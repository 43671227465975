const getPending = (data) => {
  return data.filter((lr) => lr.reviewerDecision === null);
};

const getApproved = (data) => {
  return data.filter((lr) => lr.reviewerDecision === 1);
};

const getRejected = (data) => {
  return data.filter((lr) => lr.reviewerDecision === 0);
};

export default {
  getPending,
  getApproved,
  getRejected
};
