import { Avatar, ButtonBase } from '@mui/material';
import { IconSettings } from '@tabler/icons';
import { Tooltip } from 'antd';
import { GetAccessByPermissions } from 'utils/permissions';
import { useHeaderHook } from '../useHeaderHook';

export const AppSettingsButton = () => {
  const { navigate, CASL_OBJECTS, theme } = useHeaderHook();
  return GetAccessByPermissions(CASL_OBJECTS.APPLICATION_SETTINGS_GROUP) ? (
    <Tooltip title="Application Settings">
      <ButtonBase sx={{ borderRadius: '12px' }}>
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            transition: 'all .2s ease-in-out',
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.dark,
            '&[aria-controls="menu-list-grow"],&:hover': {
              background: theme.palette.secondary.dark,
              color: theme.palette.secondary.light
            }
          }}
          onClick={() => {
            navigate(CASL_OBJECTS.APPLICATION_SETTINGS_GROUP.path);
          }}
          color="inherit"
        >
          <IconSettings stroke={1.5} size="1.3rem" />
        </Avatar>
      </ButtonBase>
    </Tooltip>
  ) : (
    <></>
  );
};
