// assets
import {
  IconBusinessplan,
  IconCalendarOff,
  IconCoin,
  IconColumns,
  IconDashboard,
  IconDiscount2,
  IconFileDescription,
  IconFileDollar,
  IconFloatCenter,
  IconListNumbers,
  IconRotateClockwise2,
  IconAB2
} from '@tabler/icons';
import Can from 'casl/can';
import * as CASL from 'casl/constants';
import OBJECTS from 'casl/objects';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { invoiceManagementActions } from 'store/invoice-management';
import { leaveRequestActions } from 'store/leave-request/leave-request.slice';
import useProjectManagersHook from 'views/timesheet/project-managers-timesheets/useProjectManagersHook';

// constant
const icons = {
  IconDashboard,
  IconListNumbers,
  IconBusinessplan,
  IconRotateClockwise2,
  IconFloatCenter,
  IconColumns,
  IconDiscount2,
  IconCoin,
  IconCalendarOff,
  IconFileDescription,
  IconFileDollar,
  IconAB2
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
export default function ProjectSpace() {
  const dispatch = useDispatch();
  const pendingWrkpackageManagerBusinessTripRequests = useSelector(
    (state) => state.leaveRequests.workpackageManagerListOfPendingRequests
  );
  const pendingInvoices = useSelector((state) => state.projectInvoices.pendingInvoices);
  const { allProjectsTimesheets } = useProjectManagersHook();
  const employeeResponsibilities = useSelector((state) => state.currentUser.responsibilities);
  const { timesheetAutoReview, moduleEnableLeaves, moduleEnableProjectInvoices } = useSelector(
    (state) => state.appConfiguration
  );

  useEffect(() => {
    if (moduleEnableProjectInvoices) {
      if (
        employeeResponsibilities.projectDirector ||
        Can(CASL.CASL_APPROVE, CASL_OBJECTS.INVOICE_MANAGEMENT_PERMISSIONS.childrenObjects.INVOICE_MANAGEMENT.id)
      ) {
        dispatch(invoiceManagementActions.getAllPending());
      }
    }
    if (moduleEnableLeaves && employeeResponsibilities.workPackageManager) {
      dispatch(leaveRequestActions.getPendingBusinessTripByWorkpackageManager());
    }
  }, []);
  const CASL_OBJECTS = OBJECTS({});

  return {
    id: CASL_OBJECTS.PROJECT_SETTINGS_GROUP.childrenObjects.PROJECTS.id,
    title: 'Projects Space',
    type: 'group',
    children: [
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.PROJECT_SETTINGS_GROUP.childrenObjects.PROJECTS.id)
        ? [
            {
              id: CASL_OBJECTS.PROJECT_SETTINGS_GROUP.childrenObjects.PROJECTS.id,
              title: 'Projects',
              type: 'item',
              url: CASL_OBJECTS.PROJECT_SETTINGS_GROUP.childrenObjects.PROJECTS.path,
              icon: icons.IconListNumbers,
              breadcrumbs: false
            },
            {
              id: 'workpackages',
              title: 'Workpackages',
              type: 'hidden',
              url: '/workpackages',
              icon: icons.IconListNumbers,
              breadcrumbs: false
            }
          ]
        : []),
      ...(employeeResponsibilities.workPackageManager
        ? [
            {
              id: CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.PM_LEAVE_REQUESTS_ALL.id,
              title: 'Business Trip Requests',
              type: 'item',
              url: CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.PM_LEAVE_REQUESTS_ALL.path,
              icon: icons.IconRotateClockwise2,
              badge: pendingWrkpackageManagerBusinessTripRequests.length,
              breadcrumbs: false,
              isHidden: !moduleEnableLeaves
            },
            {
              id: CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.PM_LEAVE_REQUESTS_DETAILS.id,
              title: 'Business Trip Request Details',
              type: 'hidden',
              url: CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.PM_LEAVE_REQUESTS_DETAILS.path,
              icon: icons.IconRotateClockwise2,
              breadcrumbs: false,
              isHidden: !moduleEnableLeaves
            }
          ]
        : []),
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.PROJECT_TEAM_GROUP.childrenObjects.PROJECT_MANAGER_TIMESHEETS.id) ||
      employeeResponsibilities.projectDirector
        ? [
            {
              id: CASL_OBJECTS.PROJECT_TEAM_GROUP.childrenObjects.PROJECT_MANAGER_TIMESHEETS.id,
              title: 'My Project Timesheets',
              type: 'item',
              url: CASL_OBJECTS.PROJECT_TEAM_GROUP.childrenObjects.PROJECT_MANAGER_TIMESHEETS.path,
              icon: icons.IconCalendarOff,
              badge: allProjectsTimesheets.length,
              breadcrumbs: false,
              isHidden: timesheetAutoReview
            }
          ]
        : []),
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.INVOICE_MANAGEMENT_PERMISSIONS.childrenObjects.INVOICE_MANAGEMENT.id) ||
      employeeResponsibilities.projectDirector
        ? [
            {
              id: CASL_OBJECTS.INVOICE_MANAGEMENT_PERMISSIONS.childrenObjects.INVOICE_MANAGEMENT.id,
              title: 'Project Invoices',
              type: 'item',
              url: '/projects/invoice-management',
              icon: icons.IconFileDollar,
              badge: pendingInvoices.length,
              breadcrumbs: false,
              isHidden: !moduleEnableProjectInvoices
            }
          ]
        : []),
      ...(Can(CASL.CASL_UPDATE, CASL_OBJECTS.PROJECT_SETTINGS_GROUP.childrenObjects.DELEGATE_PROJECT_MANAGER.id)
        ? [
            {
              id: CASL_OBJECTS.PROJECT_SETTINGS_GROUP.childrenObjects.DELEGATE_PROJECT_MANAGER.id,
              title: 'Delegate Managers',
              type: 'item',
              url: CASL_OBJECTS.PROJECT_SETTINGS_GROUP.childrenObjects.DELEGATE_PROJECT_MANAGER.path,
              icon: icons.IconAB2,
              breadcrumbs: false
            }
          ]
        : [])
    ]
  };
}
