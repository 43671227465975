// assets
import { IconCheckupList, IconClockHour7, IconReport } from '@tabler/icons';
import Can from 'casl/can';
import * as CASL from 'casl/constants';
import OBJECTS from 'casl/objects';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { leaveRequestActions } from 'store/leave-request/leave-request.slice';
import { overtimeRequestActions } from 'store/overtime-request';
import { makeNewBusinessTripArray, makeNewUnpaidLeaveArray } from 'views/leave-requests/cardsdata';
import businessTripRequestDataHandler from 'views/leave-requests/data-handler/business-trip-request-data-handler';
import unpaidLeaveDataHandler from 'views/leave-requests/data-handler/unpaid-leave-data-handler';

// constant
const icons = { IconReport, IconCheckupList, IconClockHour7 };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

export default function PersonalSpace() {
  const { moduleEnableLeaves, moduleEnableOvertime } = useSelector((state) => state.appConfiguration);
  const leaveRequestTypes = useSelector((state) => state.leaveRequests.leaveTypes);

  const dispatch = useDispatch();
  const findIdByTypeName = (typeName) => {
    const id = leaveRequestTypes.find((type) => type.name === typeName).id;
    return id;
  };

  useEffect(() => {
    if (
      moduleEnableOvertime &&
      Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_OVERTIME_REQUESTS.id)
    ) {
      dispatch(overtimeRequestActions.getAllOvertimeRequestsByEmployeeId());
    }
    if (
      moduleEnableLeaves &&
      Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.id)
    ) {
      dispatch(leaveRequestActions.getMyBusinessTripRequests(findIdByTypeName('Business Trip Request')));
      dispatch(leaveRequestActions.getMyUnpaidLeaveRequests(findIdByTypeName('Unpaid Leave Request')));
      dispatch(leaveRequestActions.getMyPaidLeaveRequests(findIdByTypeName('Paid Leave Request')));
    }
  }, []);
  const myBusinessTripRequests = useSelector((state) => state.leaveRequests.myBusinessTripRequests);
  const myUnpaidLeaveRequests = useSelector((state) => state.leaveRequests.myUnpaidLeaveRequests);

  const pendingBusinessTrips = makeNewBusinessTripArray(
    businessTripRequestDataHandler.getRejected(myBusinessTripRequests)
  );
  const pendingUnpaidLeaves = makeNewUnpaidLeaveArray(unpaidLeaveDataHandler.getRejected(myUnpaidLeaveRequests));

  const overtimeRequests = useSelector((state) => state.overtimeRequests.myOvertimeRequests);

  const rejectedOvertimeRequests = overtimeRequests?.filter((or) => or.approverDecision === false);

  const CASL_OBJECTS = OBJECTS({});
  return {
    id: 'my-personal-space',
    title: 'My Personal Space',
    type: 'group',
    children: [
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_TIME_BOOKING.id)
        ? [
            {
              id: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_TIME_BOOKING.id,
              title: 'My Time Booking',
              type: 'item',
              url: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_TIME_BOOKING.path,
              icon: icons.IconReport,
              breadcrumbs: false
            },
            {
              id: 'my-timebooking',
              title: 'Submit Timesheet',
              type: 'hidden',
              url: '/my-personal-space/my-timebooking/submit-timesheet',
              icon: icons.IconReport,
              breadcrumbs: false
            }
          ]
        : []),
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.id)
        ? [
            {
              id: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.id,
              title: 'My Leave Requests',
              type: 'item',
              url: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.path,
              icon: icons.IconCheckupList,
              badge: pendingBusinessTrips.length > 0 || pendingUnpaidLeaves.length > 0,
              breadcrumbs: false,
              isHidden: !moduleEnableLeaves
            },
            {
              id: 'my-leave-requests',
              title: 'Submit Requests',
              type: 'hidden',
              url: '/my-personal-space/my-leave-requests/business-trip',
              icon: icons.IconCheckupList,
              breadcrumbs: false,
              isHidden: !moduleEnableLeaves
            },
            {
              id: 'my-leave-requests',
              title: 'Business Trip History',
              type: 'hidden',
              url: '/my-personal-space/my-leave-requests/history-business-trip',
              icon: icons.IconCheckupList,
              breadcrumbs: false,
              isHidden: !moduleEnableLeaves
            }
          ]
        : []),
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_OVERTIME_REQUESTS.id)
        ? [
            {
              id: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_OVERTIME_REQUESTS.id,
              title: 'My Overtime Requests',
              type: 'item',
              url: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_OVERTIME_REQUESTS.path,
              icon: icons.IconClockHour7,
              badge: rejectedOvertimeRequests.length > 0,
              breadcrumbs: false,
              isHidden: !moduleEnableOvertime
            },
            {
              id: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_OVERTIME_REQUESTS.id,
              title: 'Submit Request',
              type: 'hidden',
              url: '/my-personal-space/my-overtime-requests/overtime-request',
              icon: icons.IconClockHour7,
              breadcrumbs: false,
              isHidden: !moduleEnableOvertime
            },
            {
              id: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_OVERTIME_REQUESTS.id,
              title: 'Overtime Requests History',
              type: 'hidden',
              url: '/my-personal-space/my-overtime-requests/history-overtime-requests',
              icon: icons.IconClockHour7,
              breadcrumbs: false,
              isHidden: !moduleEnableOvertime
            }
          ]
        : [])
    ]
  };
}
