import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  all: []
};

export const { actions: disciplinesActions, reducer: disciplinesReducer } = createSlice({
  name: 'disciplines',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getDisciplinesAll(state) {
      state.loading = true;
    },
    getDisciplinesAllFulfilled(state, action) {
      state.all = action.payload.sort((a, b) => a.name.localeCompare(b.name));
    },
    postDiscipline(state) {
      state.loading = true;
    },
    postDisciplineFulfilled(state, action) {
      state.all = [...state.all, action.payload].sort((a, b) => a.name.localeCompare(b.name));
    },
    patchDiscipline(state) {
      state.loading = true;
    },
    patchDisciplineFulfilled(state, action) {
      const arr = [...state.all];
      const foundIndex = arr.findIndex((item) => item.id === action.payload.id);
      arr.splice(foundIndex, 1, action.payload);
      state.all = arr;
    },
    deleteDiscipline(state) {
      state.loading = true;
    },
    deleteDisciplineFulfilled(state, action) {
      state.all = state.all.filter((d) => d.id !== action.payload);
    }
  }
});
