import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';

export const rolesSearchFilter = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder="Search"
        value={selectedKeys[0]}
        autoFocus
        style={{ marginBottom: 8, display: 'block' }}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
        }}
        onPressEnter={() => {
          confirm();
        }}
        onBlur={() => {
          confirm();
        }}
      ></Input>
      <Space>
        <Button
          type="primary"
          onClick={() => {
            confirm();
          }}
          icon={<SearchOutlined />}
          size="small"
        >
          Search
        </Button>
        <Button
          onClick={() => {
            clearFilters();
            confirm();
          }}
          size="small"
        >
          Reset
        </Button>
      </Space>
    </div>
  );
};
