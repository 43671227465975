import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Space, Tooltip, Typography } from 'antd';
import Can from 'casl/can';
import * as CASL from 'casl/constants';
import OBJECTS from 'casl/objects';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { currencyExchangeActions } from 'store/currency-exchange/currency-exchange.slice';
import { ExchangeTable, FormModal, History } from './components';

const { Text } = Typography;

export default function Exchange() {
  const [exchangesCount, setExchangesCount] = useState(1);
  const [selectedCurrencies, setSelectedCurrencies] = useState([]);
  const CASL_OBJECTS = OBJECTS({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(currencyExchangeActions.getExchanges());
  }, []);

  const handleAddClick = () => dispatch(currencyExchangeActions.openAddModal());

  return (
    <Card
      title={
        <Space>
          <Text>Currency exchange</Text>
          <Tooltip placement="topLeft" title={'Page with currency exchange'}>
            <InfoCircleOutlined style={{ fontSize: '16px', color: '#08c' }} />
          </Tooltip>
        </Space>
      }
      extra={
        Can(CASL.CASL_CREATE, CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.CURRENCY_EXCHANGE.id) && (
          <Button onClick={handleAddClick} type="primary">
            Add Exchange
          </Button>
        )
      }
    >
      <Row gutter={[40, 40]}>
        {Array(exchangesCount)
          .fill()
          .map((_, index) => (
            <Col key={index} span={12}>
              <ExchangeTable selectedCurrencies={selectedCurrencies} setSelectedCurrencies={setSelectedCurrencies} />
            </Col>
          ))}
        <Col span={12}>
          {Can(CASL.CASL_CREATE, CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.CURRENCY_EXCHANGE.id) && (
            <Button
              onClick={() => setExchangesCount(exchangesCount + 1)}
              type="dashed"
              size="large"
              block
              style={{ height: '100%', minHeight: 200 }}
            >
              <span style={{ display: 'block', fontSize: 24 }}>+</span>
              Add new exchange
            </Button>
          )}
        </Col>
      </Row>

      <FormModal />
      <History />
    </Card>
  );
}
