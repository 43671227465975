import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { myProjectsTeamsTimesheetsActions } from 'store/my-projects-teams-timesheets';
import { projectActions } from 'store/project/project.slice';

const useProjectManagersHook = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const mainColor = useSelector((state) => state.appConfiguration.mainColor);
  const activeKey = params.setting;
  let {
    myProjectsTeamsTimesheets: { allProjectsTimesheets, selectedProject, loading }
  } = useSelector((state) => state);

  const { projectDirector } = useSelector((state) => state.currentUser.responsibilities);

  useEffect(() => {
    // TODO: Remove getAll request dispatch once back is ready
    if (projectDirector) {
      dispatch(projectActions.getMyProjects());
      dispatch(myProjectsTeamsTimesheetsActions.getAllProjectsTeamsTimesheets());
    }
    return () => {
      dispatch(myProjectsTeamsTimesheetsActions.clearSelectedItems());
    };
  }, []);

  useEffect(() => {
    if (allProjectsTimesheets.length > 0) {
      dispatch(myProjectsTeamsTimesheetsActions.setSelectedProject(allProjectsTimesheets[0].project.id));
    }
  }, [allProjectsTimesheets]);

  return { loading, dispatch, activeKey, dispatch, allProjectsTimesheets, selectedProject, mainColor };
};

export default useProjectManagersHook;
