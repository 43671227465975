import OBJECTS from 'casl/objects';
// project imports
import MainLayout from 'layout/main-layout';
import { lazy } from 'react';
import Loadable from 'ui-component/loadable';
const AdminSettings = Loadable(lazy(() => import('views/admin-settings')));

// ==============================|| MAIN ROUTING ||============================== //
export default function AdminSettingsRoutes() {
  const CASL_OBJECTS = OBJECTS({});

  return {
    path: CASL_OBJECTS.ADMINISTRATION_SETTINGS_GROUP.path,
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <AdminSettings />
      },
      {
        path: CASL_OBJECTS.ADMINISTRATION_SETTINGS_GROUP.path + '/:setting',
        element: <AdminSettings />
      }
    ]
  };
}
