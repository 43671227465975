import { createSlice } from '@reduxjs/toolkit';
import { compareAsc, parseISO } from 'date-fns';

const initialState = {
  loading: false,
  error: false,
  leaveTypes: [],
  humanResourcesRequests: [],
  myLeaveRequests: [],
  myBusinessTripRequests: [],
  myUnpaidLeaveRequests: [],
  myPaidLeaveRequests: [],
  workpackageManagerListOfRequests: [],
  workpackageManagerListOfPendingRequests: [],
  directManagerPendingListOfRequests: [],
  directManagerListOfRequestsHistory: [],
  detailsOfLeave: null,
  humanResourcesActiveTab: ''
};

export const getLeaveTypeById = (state, typeId) => {
  return state.leaveRequests.leaveTypes.find((lt) => lt.id === typeId);
};

export const getMyLeavesByTypeId = (state, typeId) => {
  const res = state.leaveRequests.myLeaveRequests.filter((lt) => lt.type === typeId);
  return res;
};

export const { actions: leaveRequestActions, reducer: leaveRequestReducer } = createSlice({
  name: 'leaveRequest',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    changeHumanResourcesActiveTab: (state, action) => {
      state.humanResourcesActiveTab = action.payload;
    },
    getLeaveRequestTypes: (state) => {
      state.loading = true;
    },
    getLeaveRequestTypesFulfilled(state, action) {
      state.leaveTypes = action.payload;
    },
    getMyBusinessTripRequests: (state) => {
      state.loading = true;
    },
    getMyBusinessTripRequestsFulfilled(state, action) {
      state.myBusinessTripRequests = action.payload;
    },
    getMyUnpaidLeaveRequests: (state) => {
      state.loading = true;
    },
    getMyUnpaidLeaveRequestsFulfilled(state, action) {
      state.myUnpaidLeaveRequests = action.payload;
    },
    getMyPaidLeaveRequests: (state) => {
      state.loading = true;
    },
    getMyPaidLeaveRequestsFulfilled(state, action) {
      state.myPaidLeaveRequests = action.payload;
    },
    getHRLeaveRequestsAll: (state) => {
      state.loading = true;
    },
    getHRLeaveRequestsAllFulfilled(state, action) {
      state.humanResourcesRequests = action.payload.sort((a, b) =>
        compareAsc(parseISO(b.submittedOn), parseISO(a.submittedOn))
      );
    },
    getPendingLeaveRequestsByDirectManagerId: (state) => {
      state.loading = true;
    },
    getPendingLeaveRequestsByDirectManagerIdFulfilled(state, action) {
      state.directManagerPendingListOfRequests = action.payload;
    },
    getPendingBusinessTripByWorkpackageManager: (state) => {
      state.loading = true;
    },
    getPendingBusinessTripByWorkpackageManagerFulfilled(state, action) {
      state.workpackageManagerListOfPendingRequests = action.payload;
    },
    getHistoryOfRequestsByLineManager: (state) => {
      state.loading = true;
    },
    getHistoryOfRequestsByLineManagerFulfilled(state, action) {
      state.directManagerListOfRequestsHistory = action.payload;
    },
    getHistoryBusinessTripByWorkpackageManager: (state) => {
      state.loading = true;
    },
    getHistoryBusinessTripByWorkpackageManagerFulfilled(state, action) {
      state.workpackageManagerListOfRequests = action.payload;
    },
    submitLeaveRequest(state) {
      state.loading = true;
    },
    submitLeaveRequestFulfilled(state, action) {},
    patchLeaveRequest: (state) => {
      state.loading = true;
    },
    patchLeaveRequestFulfilled(state, action) {
      let newULR = [...state.myLeaveRequests];
      const index = newULR.findIndex((item) => item.id === action.payload.id);
      newULR.splice(index, 1, action.payload);
      state.myLeaveRequests = [...newULR];
    },
    workpackageManagerDecision(state) {
      state.loading = true;
    },
    workpackageManagerDecisionFulfilled(state, action) {},
    deleteHrRequestById(state) {
      state.loading = true;
    },
    deleteHrRequestByIdFulfilled(state, action) {
      state.humanResourcesRequests = [...state.humanResourcesRequests].filter((req) => req.id !== action.payload);
    },
    deleteEmployeeBusinessTrip(state) {
      state.loading = true;
    },
    deleteEmployeeBusinessTripFulfilled(state, action) {
      state.myBusinessTripRequests = [...state.myBusinessTripRequests].filter((req) => req.id !== action.payload);
    }
  }
});
