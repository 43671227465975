import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  all: []
};

export const selectCurrencyById = (state, currencyId) => {
  const hist = state.currencies.all.find((pc) => pc.id === currencyId);
  return hist;
};

export const { actions: currencyActions, reducer: currencyReducer } = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getCurrenciesAll(state) {
      state.loading = true;
    },
    getCurrenciesAllFulfilled(state, action) {
      state.all = action.payload;
    },
    postCurrency(state) {
      state.loading = true;
    },
    postCurrencyFulfilled(state, action) {
      state.all = [...state.all, action.payload].sort((a, b) => a.name.localeCompare(b.name));
    }
  }
});
