import { List, Modal, Space, Statistic } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currencyExchangeActions } from 'store/currency-exchange/currency-exchange.slice';
import { HistoryListItem } from './history-list-item';
import { currenciesinfo } from 'utils/currency-list';
import { IconArrowRight } from '@tabler/icons';
import { getCurrency } from 'views/currency-exchange/utils';

export const History = () => {
  const dispatch = useDispatch();

  const {
    currencyExchanges: { historyModalOpen, historyItem, exchanges },
    currencies: { all: currencies }
  } = useSelector((state) => state);

  const [editingExchange, setEditingExchange] = useState();

  const handleCancel = () => {
    dispatch(currencyExchangeActions.closeHistory());
    setEditingExchange(null);
  };

  if (!historyItem || !currencies) return null;

  const currentConversions = exchanges?.[historyItem.fromCurrency]?.find(
    (currency) => currency.toCurrency === historyItem.toCurrency
  ).history;

  const fromCurrency = getCurrency(historyItem?.fromCurrency, currencies);
  const toCurrency = getCurrency(historyItem?.toCurrency, currencies);

  return (
    <Modal onCancel={handleCancel} open={historyModalOpen} footer={null}>
      <Space size={20}>
        <Statistic
          title={fromCurrency?.name}
          value={
            fromCurrency &&
            fromCurrency.code +
              ' ' +
              currenciesinfo.find((currencyInfo) => fromCurrency.code === currencyInfo.code).symbol
          }
        />
        <IconArrowRight />
        <Statistic
          title={toCurrency?.name}
          value={
            toCurrency &&
            toCurrency.code + ' ' + currenciesinfo.find((currencyInfo) => toCurrency.code === currencyInfo.code).symbol
          }
        />
      </Space>
      <List style={{ height: 500, overflow: 'auto' }} size="small">
        {!editingExchange && currentConversions
          ? currentConversions
              .map((history) => <HistoryListItem key={history.id} exchange={history} currencies={currencies} />)
              .reverse()
          : null}
      </List>
    </Modal>
  );
};
