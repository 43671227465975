import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const vatsServices = {
  getVatsAll() {
    return axios.get(API_PATH + '/invoiceVat/all');
  },
  postVat(vat) {
    return axios.post(API_PATH + '/invoiceVat', vat);
  },
  patchVat(payload) {
    return axios.patch(API_PATH + '/invoiceVat', payload);
  },
  deleteVat(vat) {
    return axios.delete(API_PATH + '/invoiceVat', {
      data: vat
    });
  }
};
