// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { Badge } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { menuOpen, setCollapse } from 'store/actions';
import { customizationActions } from 'store/customization';
// project imports
import NavItem from '../nav-item';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const customization = useSelector((state) => state.customization);

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  // customization.collapseSelected?.id ? setOpen(true) : '';

  const handleClick = () => {
    setOpen(!open);
    setSelected(!selected ? menu.id : null);
  };

  // menu collapse & item
  const menus = menu.children
    ?.filter((item) => !item.isHidden)
    .map((item) => {
      switch (item.type) {
        case 'collapse':
          return <NavCollapse key={item.id} menu={item} level={level + 1} />;
        case 'item':
          return <NavItem parent={menu} key={item.id} item={item} level={level + 1} />;
        case 'hidden':
          return '';
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Collapse Items Error
            </Typography>
          );
      }
    });

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname.toString().split('/');
    if (currentIndex.includes(menu.id)) {
      // if (currentIndex[currentIndex.length - 2] === menu.id) {
      setOpen(true);
      setSelected(true);
      dispatch(customizationActions.menuOpen(currentIndex[currentIndex.length - 1]));
      if (menu?.type === 'collapse') dispatch(customizationActions.setCollapse(menu));
    } else {
      setOpen(false);
      setSelected(null);
    }
  }, [document.location.pathname]);

  const Icon = menu.icon;
  const menuIcon = menu.icon ? (
    <Icon strokeWidth={1.5} size="1.2rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: selected === menu.id ? 8 : 6,
        height: selected === menu.id ? 8 : 6
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  return (
    <>
      <ListItemButton
        sx={{
          borderRadius: `${customization.borderRadius}px`,
          mb: 0.3,
          alignItems: 'flex-start',
          backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
          py: level > 1 ? 0.1 : 0.3,
          pl: `${level * 12}px`
        }}
        selected={menu.id === customization.collapseSelected || selected === menu.id}
        onClick={handleClick}
      >
        <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 14 : 28 }}>{menuIcon}</ListItemIcon>
        <ListItemText
          primary={
            <Badge dot={menu.badge > 0} offset={[10, 8]}>
              <Typography variant={selected === menu.id ? 'h6' : 'body1'} color="inherit" sx={{ my: 'auto' }}>
                {menu.title}
              </Typography>
            </Badge>
          }
          secondary={
            menu.caption && (
              <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                {menu.caption}
              </Typography>
            )
          }
        />
        {open ? (
          <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
        ) : (
          <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{
            position: 'relative',
            '&:after': {
              content: "''",
              position: 'absolute',
              left: '14px',
              top: 0,
              height: '100%',
              width: '1px',
              opacity: 1,
              background: theme.palette.orange.main
            }
          }}
        >
          {menus}
        </List>
      </Collapse>
    </>
  );
};

NavCollapse.propTypes = {
  menu: PropTypes.object,
  level: PropTypes.number
};

export default NavCollapse;
