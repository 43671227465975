import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useTimesheet } from '../../hooks';

export const DeleteRow = ({ index }) => {
  const { values, setFieldValue } = useFormikContext();
  const { disabledActivities } = useTimesheet();

  const [isPopupOpen, setPopupOpen] = useState(false);

  const handleOkClick = () => {
    setFieldValue('timeSheetEntryPerActivities', [
      ...values.timeSheetEntryPerActivities.slice(0, index),
      ...values.timeSheetEntryPerActivities.slice(index + 1)
    ]);
  };

  const togglerDisabled =
    values.timeSheetEntryPerActivities.length <= 1 ||
    disabledActivities.includes(values.timeSheetEntryPerActivities?.[index].id);

  const handleTogglerClick = () => {
    if (!togglerDisabled) setPopupOpen(!isPopupOpen);
  };

  return (
    <Popconfirm
      open={isPopupOpen && !togglerDisabled}
      okButtonProps={{ onClick: handleOkClick }}
      onCancel={() => setPopupOpen(false)}
      placement="left"
      title={'Sure to delete?'}
    >
      <Button
        style={{ border: 'none' }}
        icon={<DeleteOutlined />}
        disabled={togglerDisabled}
        onClick={handleTogglerClick}
      />
    </Popconfirm>
  );
};
