import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { invoicePaymentsServices } from './invoice-payments.services';
import { invoicePaymentsActions } from './invoice-payments.slice';

export function* invoicePaymentsRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getInvoicePaymentsByInvoiceId',
      actions: invoicePaymentsActions,
      api: {
        method: invoicePaymentsServices.getInvoicePaymentsByInvoiceId,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postInvoicePayment',
      actions: invoicePaymentsActions,
      api: {
        method: invoicePaymentsServices.postInvoicePayment,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchInvoicePayment',
      actions: invoicePaymentsActions,
      api: {
        method: invoicePaymentsServices.patchInvoicePayment,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteInvoicePayment',
      actions: invoicePaymentsActions,
      api: {
        method: invoicePaymentsServices.deleteInvoicePayment,
        callback: (res) => res.data.data
      }
    })
  ]);
}
