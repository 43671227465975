import { Card } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TimesheetProvider } from '../../contexts';
import { formatEntryPerActivityByDays } from '../../utils';
import { EditableTimesheet } from '../editable-timesheet';
import { HoursNotification } from '../hours-notification';
import { NonEditableTimesheet } from '../non-editable-timesheet';
import { TimesheetTimeline } from '../timesheet-timeline';

export const Timesheet = ({
  editable = true,
  footer = null,
  timesheet: defaultTimesheet,
  weekInfo = [],
  dayTargetHours,
  pdCommentShown,
  tableTitle,
  columns,
  disabledActivities
}) => {
  const { days, loading } = useSelector((state) => state.timesheet);
  const timesheetSettingsLoading = useSelector((state) => state.timesheetSettings.loading);

  const [timesheet, setTimesheet] = useState(defaultTimesheet);
  const storeTimesheet = useSelector((state) => state.timesheet.timesheet);

  useEffect(() => {
    if (days?.days) {
      setTimesheet(
        defaultTimesheet
          ? {
              ...defaultTimesheet,
              timeSheetEntryPerActivities: defaultTimesheet.timeSheetEntryPerActivities.map((entryPerActivity) => ({
                ...entryPerActivity,
                dayActivityLinks: formatEntryPerActivityByDays(days.days, entryPerActivity.dayActivityLinks)
              }))
            }
          : null
      );
    }
  }, [defaultTimesheet]);

  useEffect(() => {
    if (days?.days) {
      setTimesheet(
        storeTimesheet && {
          ...storeTimesheet,
          timeSheetEntryPerActivities: storeTimesheet.timeSheetEntryPerActivities.map((entryPerActivity) => ({
            ...entryPerActivity,
            projectId: entryPerActivity.project.id,
            activityCascader: [entryPerActivity.subproject.id, entryPerActivity.activity.id],
            activityId: entryPerActivity.activity.id,
            dayActivityLinks: formatEntryPerActivityByDays(days.days, entryPerActivity.dayActivityLinks)
          }))
        }
      );
    }
  }, [storeTimesheet]);

  const isTimesheetEditable =
    typeof editable === 'boolean' ? editable : typeof editable === 'function' ? (() => editable(timesheet))() : false;

  const isPdCommentShown =
    typeof pdCommentShown === 'boolean'
      ? pdCommentShown
      : typeof pdCommentShown === 'function'
      ? (() => pdCommentShown(timesheet))()
      : false;

  disabledActivities =
    typeof disabledActivities === 'function' ? disabledActivities(timesheet) : disabledActivities || [];

  return (
    <TimesheetProvider
      defaultTimesheet={defaultTimesheet}
      timesheet={timesheet}
      setTimesheet={setTimesheet}
      weekInfo={weekInfo}
      dayTargetHours={dayTargetHours}
      pdCommentShown={isPdCommentShown}
      disabledActivities={disabledActivities}
    >
      <Card title={<TimesheetTimeline />} extra={<HoursNotification />}>
        {isTimesheetEditable ? (
          <EditableTimesheet
            tableTitle={tableTitle}
            days={days?.days}
            loading={loading || timesheetSettingsLoading}
            columns={columns}
          />
        ) : (
          <NonEditableTimesheet
            tableTitle={tableTitle}
            timesheetEntries={timesheet?.timeSheetEntryPerActivities || []}
            loading={loading || timesheetSettingsLoading}
          />
        )}
        {footer}
      </Card>
    </TimesheetProvider>
  );
};
