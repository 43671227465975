import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { jobTitlesServices } from './job-titles.services';
import { jobTitlesActions } from './job-titles.slice';

export function* jobTitlesRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getJobTitlesAll',
      actions: jobTitlesActions,
      api: {
        method: jobTitlesServices.getJobTitlesAll,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postJobTitle',
      actions: jobTitlesActions,
      api: {
        method: jobTitlesServices.postJobTitle,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchJobTitle',
      actions: jobTitlesActions,
      api: {
        method: jobTitlesServices.patchJobTitle,
        callback: (res) => res.data.data
      }
    }),
    ,
    fetchSaga({
      pattern: 'deleteJobTitle',
      actions: jobTitlesActions,
      api: {
        method: jobTitlesServices.deleteJobTitle,
        callback: (res) => res.data.data
      }
    })
  ]);
}
