import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  all: [],
  newBudget: null
};

export const { actions: budgetActions, reducer: budgetReducer } = createSlice({
  name: 'budget',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getAllBudgetsByWorkpackageId(state) {
      state.loading = true;
    },
    getAllBudgetsByWorkpackageIdFulfilled(state, action) {
      let budgets = [...action.payload];
      return {
        ...state,
        all: budgets
      };
    },
    submitNewBudget(state) {
      state.loading = true;
    },
    submitNewBudgetFulfilled(state, action) {},
    deleteBudget(state) {
      state.loading = true;
    },
    deleteBudgetFulfilled(state, action) {
      state.all = state.all.filter((b) => b.id != action.payload);
    },
    postSubcontractorTechnicalProgress(state) {
      state.loading = true;
    },
    postSubcontractorTechnicalProgressFulfilled(state, action) {
      const foundBudget = state.all.find((budget) => budget.id === action.payload.subprojectBudgetId);
      const foundSubcontractor =
        foundBudget &&
        foundBudget?.subprojectBudgetSubcontractors.find((subcontractor) => subcontractor.id === action.payload.id);
      if (foundSubcontractor) {
        foundSubcontractor.technicalProgress = action.payload.technicalProgress;
      }
    },
    post3rdPartyRealValue(state) {
      state.loading = true;
    },
    post3rdPartyRealValueFulfilled(state, action) {
      const foundBudget = state.all.find((budget) => budget.id === action.payload.subprojectBudgetId);
      const found3rdPartyCost =
        foundBudget &&
        foundBudget?.subprojectBudgetThirdPartyCosts.find((subcontractor) => subcontractor.id === action.payload.id);
      if (found3rdPartyCost) {
        found3rdPartyCost.realValue = action.payload.realValue;
      }
    }
  }
});
