import { createSlice } from '@reduxjs/toolkit';
import { compareDesc, parseISO } from 'date-fns';

const initialState = {
  loading: false,
  error: false,
  all: []
};

export const { actions: forecastActions, reducer: forecastReducer } = createSlice({
  name: 'forecast',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getForecasts: (state) => {
      state.loading = true;
    },
    getForecastsFulfilled(state, action) {
      state.all = action.payload;
    },
    postForecast(state) {
      state.loading = true;
    },
    postForecastFulfilled(state, action) {
      const foundForecast = state.all.find((forecast) => forecast.activityType.id === action.payload.activityType.id);

      if (foundForecast) {
        foundForecast.forecastHistories
          .splice(0, foundForecast.forecastHistories.length, ...action.payload.forecastHistories)
          .sort((a, b) => a.year - b.year);
      } else {
        state.all = [...state.all, action.payload].sort((a, b) =>
          a.activityType.name.localeCompare(b.activityType.name)
        );
      }
    },
    patchForecast(state) {
      state.loading = true;
    },
    patchForecastFulfilled(state, action) {
      let updatedForecasts = [...state.all];
      const foundForecast = state.all.find((forecast) => forecast.activityType.id === action.payload.activityType.id);

      const index = foundForecast.forecastHistories.findIndex((hist) => hist.id === action.payload.id);

      delete action.payload['activityType'];
      foundForecast.forecastHistories.splice(index, 1, action.payload);
      foundForecast.forecastHistories.sort((a, b) => b.year - a.year);
      state.all = [...updatedForecasts];
    },
    deleteByForecastId(state) {
      state.loading = true;
    },
    deleteByForecastIdFulfilled(state, action) {
      const response = action.payload;
      state.all = state.all.map((forecast) => ({
        ...forecast,
        forecastHistories: forecast.forecastHistories.filter((fr) => fr.id !== response)
      }));
    },
    deleteByActivityTypeId(state) {
      state.loading = true;
    },
    deleteByActivityTypeIdFulfilled(state, action) {
      const response = action.payload;
      state.all = state.all.filter((d) => d.activityType.id !== response);
    }
  }
});
