import OBJECTS from 'casl/objects';
import MainLayout from 'layout/main-layout';
import CurrencyExchange from 'views/currency-exchange';

export default function CurrencyExchangeRoutes() {
  return {
    path: OBJECTS({}).VARIOUS_GROUP_SETTINGS.childrenObjects.CURRENCY_EXCHANGE.path,
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <CurrencyExchange />
      }
    ]
  };
}
