export const calculateWeekHours = function () {
  let hoursPerDay = Array(7)
    .fill()
    .map(() => {
      return { manHours: 0 };
    });

  let totalHours = this?.timeSheetEntryPerActivities?.reduce((accumulator, epa) => {
    return (
      accumulator +
      epa.dayActivityLinks.reduce((activityAccumulator, da, dayIndex) => {
        const dayInHoursPerDay = hoursPerDay[dayIndex];
        if (dayInHoursPerDay) {
          dayInHoursPerDay.manHours = dayInHoursPerDay ? dayInHoursPerDay.manHours + da.manHours : 0;
        }
        return activityAccumulator + (da?.manHours || 0);
      }, 0)
    );
  }, 0);

  return { hoursPerDay: hoursPerDay, totalHours: totalHours || 0 };
};
