import { Badge, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';

// material-ui
import { Avatar, ButtonBase } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// assets
import { IconBell } from '@tabler/icons';
import { useDispatch, useSelector } from 'react-redux';
import { notificationsActions } from 'store/notifications';
import NotificationSection from '../notification-section';

// ==============================|| NOTIFICATION ||============================== //

const NotificationButton = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const unreadNotifications = useSelector((state) => state.notifications.unreadNotifications);

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Tooltip title="Notifications">
        <Badge dot={unreadNotifications.length > 0}>
          <ButtonBase sx={{ borderRadius: '12px' }}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                background: theme.palette.secondary.main,
                color: theme.palette.secondary.dark,
                '&[aria-controls="menu-list-grow"],&:hover': {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light
                }
              }}
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={() => {
                handleToggle();
              }}
              color="inherit"
            >
              <IconBell stroke={1.5} size="1.3rem" />
            </Avatar>
          </ButtonBase>
        </Badge>
      </Tooltip>
      <NotificationSection handleClose={handleClose} anchorRef={anchorRef} open={open} setOpen={setOpen} />
    </>
  );
};

export default NotificationButton;
