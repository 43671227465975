import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  all: []
};

export const { actions: caslRolesPrivilegesActions, reducer: caslRolesPrivilegesReducer } = createSlice({
  name: 'caslRolesPrivileges',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getCaslRolesPrivilegesAll(state) {
      state.loading = true;
    },
    getCaslRolesPrivilegesAllFulfilled(state, action) {
      state.all = action.payload;
    },
    postCaslRolesPrivileges(state) {
      state.loading = true;
    },
    postCaslRolesPrivilegesFulfilled(state, action) {
      const initialValues = [...state.all];
      action.payload.map((caslRolePrivilege) => {
        const index = initialValues.findIndex((ro) => ro.role.id == caslRolePrivilege.caslRoleId);
        let fullObject = {};
        if (index > -1) {
          fullObject = initialValues[index];
        } else {
          fullObject = { role: { id: caslRolePrivilege.caslRoleId, name: '' }, permissions: [] };
        }
        const tempPermissions = fullObject.permissions;
        const objectIndex = tempPermissions.findIndex((fo) => fo.object === caslRolePrivilege.object);
        const tempPrivileges = {
          privilege: caslRolePrivilege.privilege,
          id: caslRolePrivilege.id
        };
        if (objectIndex > -1) {
          const obj = tempPermissions[objectIndex];
          const tempObj = { object: caslRolePrivilege.object, privileges: [...obj.privileges, tempPrivileges] };
          tempPermissions[objectIndex] = tempObj;
        } else {
          const obj = { object: caslRolePrivilege.object, privileges: [tempPrivileges] };
          tempPermissions.push(obj);
        }

        fullObject.permissions = tempPermissions;
        if (index > -1) {
          initialValues[index] = fullObject;
        } else {
          initialValues.push(fullObject);
        }
      });

      state.all = initialValues;
    },
    deleteCaslRolesPrivilege(state) {
      state.loading = true;
    },
    deleteCaslRolesPrivilegeFulfilled(state, action) {
      const relations = action.payload;
      const initialValues = [...state.all].map((value) => ({
        ...value,
        permissions: value.permissions
          .map((permission) => ({
            ...permission,
            privileges: permission.privileges.filter((privilege) => !relations.includes(privilege.id))
          }))
          .filter((pri) => pri.privileges.length > 0)
      }));
      state.all = initialValues;
    }
  }
});
