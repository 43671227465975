import { all, put, takeLatest } from 'redux-saga/effects';
import { projectActions } from 'store/project/project.slice';
import { fetchSaga } from 'utils/fetch-saga';
import { projectWorpakacageManagerDelegationServices } from './project-workpackages-managers-delegation.services';
import { projectWorpakacageManagerDelegationActions } from './project-workpackages-managers-delegation.slice';
export function* projectWorpakacageManagerDelegationRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getAllProjectManagers',
      actions: projectWorpakacageManagerDelegationActions,
      api: {
        method: projectWorpakacageManagerDelegationServices.getAllProjectManagers,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getAllWorkpackageManagers',
      actions: projectWorpakacageManagerDelegationActions,
      api: {
        method: projectWorpakacageManagerDelegationServices.getAllWorkpackageManagers,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getAllProjectsByManagerId',
      actions: projectWorpakacageManagerDelegationActions,
      api: {
        method: projectWorpakacageManagerDelegationServices.getAllProjectsByManagerId,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getAllWorkpackagesByManagerId',
      actions: projectWorpakacageManagerDelegationActions,
      api: {
        method: projectWorpakacageManagerDelegationServices.getAllWorkpackagesByManagerId,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'permanentlyDelegateManager',
      actions: projectWorpakacageManagerDelegationActions,
      api: {
        method: projectWorpakacageManagerDelegationServices.permanentlyDelegateManager,
        callback: (res) => res.data.data
      }
    }),
    takeLatest(
      projectWorpakacageManagerDelegationActions.permanentlyDelegateManagerFulfilled.type,
      dispatchResetSliceManagers
    )
  ]);
}

// for default workpackage on project creation
function* dispatchResetSliceManagers(action) {
  const isProject = action.payload.isProject;
  if (isProject) {
    yield put(projectActions.updateProjectDirectors(action.payload));
  }
  yield put(projectWorpakacageManagerDelegationActions.getAllProjectManagers());
  yield put(projectWorpakacageManagerDelegationActions.getAllWorkpackageManagers());
}
