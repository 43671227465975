import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const overtimeRequestServices = {
  getAllApprovedOvertimeRequests() {
    return axios.get(API_PATH + '/overtime/getAllApproved');
  },
  getAllPendingOvertimeRequestsByManagerId() {
    return axios.get(API_PATH + '/overtime/getPendingByManagerId');
  },
  getNonPendingOvertimeRequestsByManagerIId() {
    return axios.get(API_PATH + '/overtime/getNonPendingByManagerId/');
  },
  getAllOvertimeRequestsByEmployeeId() {
    return axios.get(API_PATH + '/overtime/getAllByEmployeeId/');
  },
  getOvertimeRequestById(requestId) {
    return axios.get(API_PATH + '/overtime/' + requestId);
  },
  submitOvertimeRequest(payload) {
    return axios.post(API_PATH + '/overtime', payload);
  },
  patchOvertimeRequest(payload) {
    return axios.patch(API_PATH + '/overtime', payload);
  },
  managerDecision(payload) {
    return axios.patch(API_PATH + '/overtime/approverDecision/', payload);
  },
  deleteOvertime(requestId) {
    return axios.delete(API_PATH + '/overtime/' + requestId);
  }
};
