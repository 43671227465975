import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const salariesServices = {
  getSalariesAll() {
    return axios.get(API_PATH + '/costing');
  },
  patchSalary(payload) {
    return axios.patch(API_PATH + '/costing/', payload);
  },
  postSalary(employee) {
    return employee;
  }
};
