import { useTimesheetInfo } from '../../hooks/useTimesheetInfo';
import { Timesheet } from '../timesheet';

export const EmployeeTimesheetWithDays = ({
  employeeId,
  weekInfo,
  buttons,
  editable,
  dayTargetHours,
  footer,
  pdCommentShown,
  tableTitle,
  columns,
  disabledActivities,
  filtersByStatus
}) => {
  const timesheet = useTimesheetInfo(employeeId, filtersByStatus);
  return (
    <Timesheet
      timesheet={timesheet}
      weekInfo={weekInfo}
      buttons={buttons}
      editable={editable}
      dayTargetHours={dayTargetHours}
      footer={footer}
      pdCommentShown={pdCommentShown}
      tableTitle={tableTitle}
      columns={columns}
      disabledActivities={disabledActivities}
    />
  );
};
