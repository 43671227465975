import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { vatsServices } from './vat.services';
import { vatsActions } from './vat.slice';

export function* vatsRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getVatsAll',
      actions: vatsActions,
      api: {
        method: vatsServices.getVatsAll,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postVat',
      actions: vatsActions,
      api: {
        method: vatsServices.postVat,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchVat',
      actions: vatsActions,
      api: {
        method: vatsServices.patchVat,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteVat',
      actions: vatsActions,
      api: {
        method: vatsServices.deleteVat,
        callback: (res) => res.data.data
      }
    })
  ]);
}
