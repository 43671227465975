import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { IconDotsVertical } from '@tabler/icons';
import { Dropdown, Space, Tooltip, Typography } from 'antd';
import Can from 'casl/can';
import * as CASL from 'casl/constants';
import { useCaslHookObjectsHook } from '../useCaslHookObjectsHook';
import OnePrivilegeOneRoleCheckbox from './components/one-privilege-one-role-checkbox';
import { PrivilegesOptions } from './privileges-by-role-options';
import { RoleOptions } from './role-privileges-options';
import { filterDataByRole } from './utils/filter-data';
import { rolesSearchFilter } from './utils/roles-search-filter';

export const permissions = [
  { title: 'C', privilege: CASL.CASL_CREATE },
  { title: 'R', privilege: CASL.CASL_READ },
  { title: 'U', privilege: CASL.CASL_UPDATE },
  { title: 'D', privilege: CASL.CASL_DELETE }
];

const DEFAULT_BORDER_COLOR = '#f0f0f0';
const HIGHLIGHTED_BORDER_COLOR = '#9d9d9d';

export const generatePermissions = (groupKey, permissionGroupIndex, perms) =>
  perms?.map((per, permissionIndex) => ({
    title: per.title,
    key: groupKey + per.privilege,
    width: 100,
    align: 'center',
    onHeaderCell: () => {
      return {
        style: {
          borderRightColor: permissionIndex === perms.length - 1 ? HIGHLIGHTED_BORDER_COLOR : DEFAULT_BORDER_COLOR
        }
      };
    },
    render: (text, record) => {
      const checked = record?.permissions
        .find((p) => p.object === groupKey)
        ?.privileges.find((pr) => {
          return pr.privilege === per.privilege;
        });
      return {
        props: {
          style: {
            background: (permissionGroupIndex % 2) - 1 ? '#ebebeb' : '#fff',
            borderRightColor: permissionIndex === perms.length - 1 ? HIGHLIGHTED_BORDER_COLOR : DEFAULT_BORDER_COLOR
          }
        },
        children: <OnePrivilegeOneRoleCheckbox checked={checked} groupKey={groupKey} record={record} permission={per} />
      };
    }
  }));

export const PermissionSettingsColumns = (data, objectKey) => {
  const { CASL_OBJECTS } = useCaslHookObjectsHook();
  const roles = data.map((dt) => ({ caslRoleName: dt.caslRoleName, caslRoleId: dt.role }));

  const groupKeys = Object.values(CASL_OBJECTS[objectKey].childrenObjects).filter((gk) => !gk.hidden);

  let columns = [
    {
      title: 'Roles',
      dataIndex: 'caslRoleName',
      fixed: 'left',
      width: 250,
      align: 'left',
      filterDropdown: rolesSearchFilter,
      filterIcon: () => {
        return <SearchOutlined style={{ fontSize: 15 }} />;
      },
      onFilter: (value, record) => {
        return roles
          .find((r) => r.caslRoleId === record.role)
          ?.caslRoleName.toLowerCase()
          .includes(value.toLowerCase());
      },
      render: (text, record) => {
        return {
          children: (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography.Text {...(record?.props || {})}>{text}</Typography.Text>
              {Can(
                CASL.CASL_UPDATE,
                CASL_OBJECTS.ADMINISTRATION_SETTINGS_GROUP.childrenObjects.PERMISSION_SETTINGS.id
              ) && (
                <Dropdown
                  overlay={
                    <RoleOptions
                      groupKeys={groupKeys}
                      record={record}
                      data={filterDataByRole({ data: data, roleId: record.role })}
                    />
                  }
                >
                  <Typography.Link>
                    <IconDotsVertical height="20px" width="20px" />
                  </Typography.Link>
                </Dropdown>
              )}
            </div>
          )
        };
      }
    },
    ...groupKeys.map((group, permissionGroupIndex) => {
      return {
        title: (
          <div className="privilege-column">
            <div className="privilege-title">{group.name}</div>
            <div className="privilege-options">
              {Can(
                CASL.CASL_UPDATE,
                CASL_OBJECTS.ADMINISTRATION_SETTINGS_GROUP.childrenObjects.PERMISSION_SETTINGS.id
              ) && (
                <Dropdown
                  overlay={
                    <PrivilegesOptions object={group.id} permissions={group.permissions} data={data} roles={roles} />
                  }
                >
                  <Space style={{ alignItems: 'flex-start' }}>
                    <Tooltip
                      placement="topLeft"
                      title={'C = Create, R = Read, U = Update, D = Delete, S = Status Update, A = Approve'}
                    >
                      <InfoCircleOutlined style={{ fontSize: '16px', color: '#08c' }} />
                    </Tooltip>
                    <Typography.Link>
                      <IconDotsVertical height="20px" width="20px" />
                    </Typography.Link>
                  </Space>
                </Dropdown>
              )}
            </div>
          </div>
        ),
        onHeaderCell: () => {
          return {
            style: {
              borderRightColor: HIGHLIGHTED_BORDER_COLOR
            }
          };
        },
        key: group.id,
        children: generatePermissions(group.id, permissionGroupIndex, group.permissions)
      };
    })
  ];
  return columns;
};
