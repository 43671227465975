import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  all: []
};

export const { actions: businessUnitActions, reducer: businessUnitReducer } = createSlice({
  name: 'businessUnit',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getBusinessUnitsAll(state) {
      state.loading = true;
    },
    getBusinessUnitsAllFulfilled(state, action) {
      state.all = action.payload;
    },
    postBusinessUnit(state) {
      state.loading = true;
    },
    postBusinessUnitFulfilled(state, action) {
      state.all = [...state.all, action.payload].sort((a, b) => a.name.localeCompare(b.name));
    },
    patchBusinessUnit(state) {
      state.loading = true;
    },
    patchBusinessUnitFulfilled(state, action) {
      const arr = [...state.all];
      const foundIndex = arr.findIndex((item) => item.id === action.payload.id);
      arr.splice(foundIndex, 1, action.payload);
      state.all = arr;
    },
    deleteBusinessUnit(state) {
      state.loading = true;
    },
    deleteBusinessUnitFulfilled(state, action) {
      state.all = state.all.filter((d) => d.id !== action.payload);
    }
  }
});
