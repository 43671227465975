import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const employeeServices = {
  getEmployees() {
    return axios.get(API_PATH + '/employee/all');
  },
  getActiveLineManagers() {
    return axios.get(API_PATH + '/employee/all/active/lineManagers');
  },
  getSubordinatesByLineManagerId(lineManagerId) {
    return axios.get(API_PATH + `/employee/all/active/subordinates?managerId=${lineManagerId}`);
  },
  getActiveEmployeesForEdit() {
    return axios.get(API_PATH + '/employee/all/active');
  },
  patchActivate(employee) {
    return employee;
  },
  patchEmployee(payload) {
    return axios.patch(API_PATH + '/employee/', payload);
  },
  delegatePermanentManager(payload) {
    return axios.post(API_PATH + '/managerDelegation/permanently-delegate-manager/all', payload);
  },
  delegateTemporaryManager(payload) {
    return axios.post(API_PATH + '/employee/temporary-delegate-manager', payload);
  },
  getEmployeePoscatHistory(payload) {
    return axios.get(API_PATH + `/employeePositionCategoryHistory?employeeId=${payload}`);
  },
  postEmployeePoscatHistory(newPoscat) {
    return axios.post(API_PATH + '/employeePositionCategoryHistory', newPoscat);
  },
  patchEmployeePoscatHistory(payload) {
    return axios.patch(API_PATH + '/employeePositionCategoryHistory/', payload);
  },
  deleteEmployeePoscatHistory(payload) {
    return axios.delete(API_PATH + '/employeePositionCategoryHistory/delete/' + payload.id);
  }
};
