import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { contractTypesServices } from './contract-types.services';
import { contractTypesActions } from './contract-types.slice';

export function* contractTypesRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getContractTypesAll',
      actions: contractTypesActions,
      api: {
        method: contractTypesServices.getContractTypesAll,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postContractType',
      actions: contractTypesActions,
      api: {
        method: contractTypesServices.postContractType,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchContractType',
      actions: contractTypesActions,
      api: {
        method: contractTypesServices.patchContractType,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteContractType',
      actions: contractTypesActions,
      api: {
        method: contractTypesServices.deleteContractType,
        callback: (res) => res.data.data
      }
    })
  ]);
}
