import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const entityServices = {
  getEntities() {
    return axios.get(API_PATH + '/entities');
  },
  getEntitiesByTypeName(payload) {
    return axios.post(API_PATH + '/entities/byEntityType', { entityTypeNames: payload });
  },
  postEntities(payload) {
    return axios.post(API_PATH + '/entities', payload);
  },
  patchEntities(payload) {
    return axios.patch(API_PATH + '/entities', payload);
  }
};
