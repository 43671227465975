import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  error: false,
  loading: false,
  all: [],
  editingParam: null,
  adding: false
};
export const { actions: powerBiParamsActions, reducer: powerBiParamsReducer } = createSlice({
  name: 'powerBiParams',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getPowerBiParamsAll(state) {
      state.loading = true;
    },
    getPowerBiParamsAllFulfilled(state, action) {
      state.all = action.payload.sort((a, b) => a.name.localeCompare(b.name));
    },
    setAddingParam(state, { payload }) {
      state.adding = payload;
    },
    postPowerBiParam(state) {
      state.loading = true;
    },
    postPowerBiParamFulfilled(state, action) {
      state.all = [...state.all, action.payload].sort((a, b) => a.name.localeCompare(b.name));
    },
    patchPowerBiParam(state) {
      state.loading = true;
    },
    patchPowerBiParamFulfilled(state, action) {
      const arr = [...state.all];
      const foundIndex = arr.findIndex((item) => item.id === action.payload.id);
      arr.splice(foundIndex, 1, action.payload);
      state.all = arr;
    },
    deletePowerBiParam: (state) => {
      state.loading = true;
    },
    deletePowerBiParamFulfilled(state, action) {
      const updatedParams = state.all.filter((param) => param.id !== action.payload);
      return {
        ...state,
        all: [...updatedParams]
      };
    }
  }
});
