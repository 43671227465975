import { qualityRiskLevelServices } from './quality-risk-level.services';
import { fetchSaga } from 'utils/fetch-saga';
import { all } from 'redux-saga/effects';
import { qualityRiskLevelActions } from './quality-risk-level.slice';

export function* qualityRiskLevelRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getQualityRiskLevels',
      actions: qualityRiskLevelActions,
      api: {
        method: qualityRiskLevelServices.getQualityRiskLevels,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postQualityRiskLevels',
      actions: qualityRiskLevelActions,
      api: {
        method: qualityRiskLevelServices.postQualityRiskLevels,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchQualityRiskLevels',
      actions: qualityRiskLevelActions,
      api: {
        method: qualityRiskLevelServices.patchQualityRiskLevels,
        callback: (res) => res.data.data
      }
    })
  ]);
}
