// material-ui
import { Box, ClickAwayListener, Paper, Popper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// assets
import { Card } from 'antd';
import Transitions from 'ui-component/extended/transitions';
import NotificationList from './notification-list';

// ==============================|| NOTIFICATION ||============================== //

const SynchronizeEmployees = ({ handleClose, anchorRef, open, setOpen }) => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Box
        sx={{
          ml: 2,
          mr: 3,
          zIndex: 999,
          [theme.breakpoints.down('md')]: {
            mr: 2
          }
        }}
      ></Box>

      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 5 : 0, 20]
              }
            }
          ]
        }}
        sx={{ zIndex: 999 }}
      >
        {({ TransitionProps }) => (
          <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Card
                  elevation={16}
                  bodyStyle={{ margin: 0, paddingTop: 0 }}
                  title={'Pending Employees for Synchronization'}
                  hoverable
                >
                  <NotificationList setOpen={setOpen} />
                </Card>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default SynchronizeEmployees;
