export const checkIndeterminateOneRoleAllObjectPermissions = (record, group) => {
  const perm = record.permissions.find((pe) => pe.object === group.id);
  if (!perm) return false;
  const something = group.permissions.map((pr) => {
    return perm.privileges.find((priv) => priv.privilege === pr.privilege);
  });

  return something.some((s) => s) && something.some((s) => !s);
};

export const checkIndeterminateOneRoleAllGroupPermissions = (recordPermissions, groupKeys) => {
  if (recordPermissions.length === 0) {
    return false;
  }
  if (recordPermissions.length !== groupKeys.length) {
    return true;
  }

  let flag = false;
  groupKeys.map((group, index) => {
    const groupPermissions = group.permissions.length;
    const rolePrivileges = recordPermissions.find((rp) => rp.object === group.id)?.privileges.length;
    if (groupPermissions !== rolePrivileges) {
      flag = true;
    }
  });

  return flag;
};
