import { Button, Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { timesheetActions } from 'store/time-sheet/time-sheet.slice';
import { useTimesheet } from '../../hooks';

export const DecisionButtons = ({ navigateBackAfterDecision = true }) => {
  const { timesheet } = useTimesheet();
  const { validateForm, values, setTouched } = useFormikContext();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const submitDecision = (isApproved) => {
    // decision
    const timesheetDecision = {
      tseId: timesheet.id,
      isApproved,
      lineManagerComments: values.lineManagerComments
    };

    dispatch(timesheetActions.approveTimeSheet(timesheetDecision));

    if (navigateBackAfterDecision) navigate(-1);
  };

  const handleRejectClick = () => {
    validateForm().then((errors) => {
      setTouched(errors);
      if (!Object.keys(errors).length) {
        submitDecision(false);
      }
    });
  };

  const handleApproveClick = () => {
    submitDecision(true);
  };

  return (
    <Row style={{ margin: '20px 0' }} justify="space-between">
      <Col span={10}>
        <Button onClick={handleRejectClick} danger type="primary" block>
          Reject
        </Button>
      </Col>
      <Col span={10}>
        <Button
          style={{
            backgroundColor: '#52c41a',
            borderColor: '#52c41a'
          }}
          type="primary"
          color="success"
          block
          onClick={handleApproveClick}
        >
          Approve
        </Button>
      </Col>
    </Row>
  );
};
