import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { powerBiParamsServices } from './powerbi.services';
import { powerBiParamsActions } from './powerbi.slice';
export function* powerBiParamsRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getPowerBiParamsAll',
      actions: powerBiParamsActions,
      api: {
        method: powerBiParamsServices.getPowerBiParamsAll,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postPowerBiParam',
      actions: powerBiParamsActions,
      api: {
        method: powerBiParamsServices.postPowerBiParam,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchPowerBiParam',
      actions: powerBiParamsActions,
      api: {
        method: powerBiParamsServices.patchPowerBiParam,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deletePowerBiParam',
      actions: powerBiParamsActions,
      api: {
        method: powerBiParamsServices.deletePowerBiParam,
        callback: (res) => res.data.data
      }
    })
  ]);
}
