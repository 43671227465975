import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';
export const powerBiParamsServices = {
  getPowerBiParamsAll() {
    return axios.get(API_PATH + '/powerbi');
  },
  postPowerBiParam(payload) {
    return axios.post(API_PATH + '/powerbi', payload);
  },
  patchPowerBiParam(payload) {
    return axios.patch(API_PATH + '/powerbi', payload);
  },
  deletePowerBiParam(payload) {
    return axios.delete(API_PATH + `/powerbi?id=${payload}`);
  }
};
