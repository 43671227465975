import { Avatar, ButtonBase } from '@mui/material';
import { IconLockAccess } from '@tabler/icons';
import { Tooltip } from 'antd';
import Can from 'casl/can';
import * as CASL from 'casl/constants';
import { useHeaderHook } from '../useHeaderHook';

export const AdminSettingsButton = () => {
  const { navigate, CASL_OBJECTS, theme } = useHeaderHook();
  const showButton =
    Can(CASL.CASL_READ, CASL_OBJECTS.ADMINISTRATION_SETTINGS_GROUP.childrenObjects.ROLE_SETTINGS.id) ||
    Can(CASL.CASL_READ, CASL_OBJECTS.ADMINISTRATION_SETTINGS_GROUP.childrenObjects.PERMISSION_SETTINGS.id);
  return showButton ? (
    <Tooltip title="Administration Settings">
      <ButtonBase sx={{ borderRadius: '12px' }}>
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            transition: 'all .2s ease-in-out',
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.dark,
            '&[aria-controls="menu-list-grow"],&:hover': {
              background: theme.palette.secondary.dark,
              color: theme.palette.secondary.light
            }
          }}
          onClick={() => {
            navigate(CASL_OBJECTS.ADMINISTRATION_SETTINGS_GROUP.path);
          }}
          color="inherit"
        >
          <IconLockAccess stroke={1.5} size="1.3rem" />
        </Avatar>
      </ButtonBase>
    </Tooltip>
  ) : (
    <></>
  );
};
