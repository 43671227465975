import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import 'antd/dist/antd.min.css';
import en_GB from 'antd/lib/locale-provider/en_GB';
import 'assets/scss/style.scss';
import 'moment/locale/en-gb';

import * as serviceWorker from 'serviceWorker';
import { store } from 'store';

import App from 'app';
import { currentUserServices } from 'store/current-user/current-user.services';
import { ssoUrl } from 'store/service';

currentUserServices.validateSession().then(async (data) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const checkNewVersion = () => {
    if (!localStorage.getItem('appVersion')) {
      localStorage.setItem('appVersion', process.env.REACT_APP_APP_VERSION);
      return true;
    }
    if (localStorage.getItem('appVersion') !== process.env.REACT_APP_APP_VERSION) {
      localStorage.setItem('appVersion', process.env.REACT_APP_APP_VERSION);
      return true;
    }
    return false;
  };

  const doesUserRedirect = async () => {
    if (data.data) {
      return false;
    } else {
      if (params.ticket) {
        const res = await currentUserServices.validateTicket(`?ticket=${params.ticket}`);

        if (res.data) {
          const session = await currentUserServices.validateSession();

          if (session.data) {
            window.location.assign('/');
            return false;
          }
          return true;
        }
        return true;
      }
      return true;
    }
  };

  const refreshForNewVersion = checkNewVersion();
  if (refreshForNewVersion) {
    window.location.reload();
  } else {
    const redirect = await doesUserRedirect();
    if (redirect) {
      window.location.assign(ssoUrl);
    } else {
      ReactDOM.render(
        <ConfigProvider locale={en_GB}>
          <Provider store={store}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Provider>
        </ConfigProvider>,
        document.getElementById('root')
      );
    }
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
