import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { positionCategoryServices } from './position-category.services';
import { positionCategoryActions } from './position-category.slice';

export function* positionCategoryRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getPositionCategories',
      actions: positionCategoryActions,
      api: {
        method: positionCategoryServices.getPositionCategories,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getActivePositionCategories',
      actions: positionCategoryActions,
      api: {
        method: positionCategoryServices.getActivePositionCategories,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postPositionCategory',
      actions: positionCategoryActions,
      api: {
        method: positionCategoryServices.postPositionCategory,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchPositionCategory',
      actions: positionCategoryActions,
      api: {
        method: positionCategoryServices.patchPositionCategory,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deletePositionCategory',
      actions: positionCategoryActions,
      api: {
        method: positionCategoryServices.deletePositionCategory,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postPositionCategoryHistory',
      actions: positionCategoryActions,
      api: {
        method: positionCategoryServices.postPositionCategoryHistory,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchPositionCategoryHistory',
      actions: positionCategoryActions,
      api: {
        method: positionCategoryServices.patchPositionCategoryHistory,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deletePositionCategoryHistory',
      actions: positionCategoryActions,
      api: {
        method: positionCategoryServices.deletePositionCategoryHistory,
        callback: (res) => res.data.data
      }
    })
  ]);
}
