import { all, put, takeLatest } from 'redux-saga/effects';
import { projectActions } from 'store/project/project.slice';
import { fetchSaga } from 'utils/fetch-saga';
import { workpackageServices } from './workpackage.services';
import { workpackageActions } from './workpackage.slice';

export function* workpackageRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getMyWorkpackages',
      actions: workpackageActions,
      api: {
        method: workpackageServices.getMyWorkpackages,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getWorkpackageById',
      actions: workpackageActions,
      api: {
        method: workpackageServices.getWorkpackageById,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getWorkpackagesByProjectId',
      actions: workpackageActions,
      api: {
        method: workpackageServices.getWorkpackagesByProjectId,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postWorkpackage',
      actions: workpackageActions,
      api: {
        method: workpackageServices.postWorkpackage,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchWorkpackage',
      actions: workpackageActions,
      api: {
        method: workpackageServices.patchWorkpackage,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchDefaultActivity',
      actions: workpackageActions,
      api: {
        method: workpackageServices.getWorkpackageById,
        callback: (res) => res.data
      }
    }),
    fetchSaga({
      pattern: 'patchDefaultWorkpackage',
      actions: workpackageActions,
      api: {
        method: workpackageServices.patchDefaultWorkpackage,
        callback: (res) => res
      }
    }),
    fetchSaga({
      pattern: 'patchChangingStatus',
      actions: workpackageActions,
      api: {
        method: workpackageServices.patchChangingStatus,
        callback: (res) => res
      }
    }),
    fetchSaga({
      pattern: 'changeWorkpackageStatus',
      actions: workpackageActions,
      api: {
        method: workpackageServices.changeWorkpackageStatus,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteWorkpackage',
      actions: workpackageActions,
      api: {
        method: workpackageServices.deleteWorkpackage,
        callback: (res) => res.data.data
      }
    }),
    takeLatest(projectActions.postProjectFulfilled.type, dispatchPatchDefaultWorkpackage),
    takeLatest(projectActions.changeProjectStatusFulfilled.type, dispatchPatchChangingStatus)
  ]);
}

// for default workpackage on project creation
function* dispatchPatchDefaultWorkpackage(action) {
  const res = yield action.payload.data;
  const project = res.data.data;
  yield put(workpackageActions.patchDefaultWorkpackage(project.subprojects[0]));
}

// for status change
function* dispatchPatchChangingStatus(action) {
  if (action.payload.projectStatus.name !== 'Ongoing') {
    yield put(workpackageActions.patchChangingStatus(action.payload));
  }
}
