import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const workpackageProgressServices = {
  getProgressByWorkpackageId(subprojectId) {
    return axios.get(API_PATH + `/subprojectProgress?subprojectId=${subprojectId}`);
  },
  createProgress(subprojectProgress) {
    return axios.post(API_PATH + '/subprojectProgress', subprojectProgress);
  },
  patchProgress(payload) {
    return axios.patch(API_PATH + '/subprojectProgress', payload);
  },
  deleteProgress(id) {
    return axios.delete(API_PATH + '/subprojectProgress/' + id);
  }
};
