import { useContext } from 'react';
import { TimesheetContext } from '../contexts';

export const useTimesheet = () => {
  const values = useContext(TimesheetContext);

  if (!values) throw new Error('useTimesheet should be called inside of TimesheetProvider');

  return values;
};
