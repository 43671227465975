import { Button, Col, Row, Space, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { timesheetActions } from 'store/time-sheet/time-sheet.slice';
import { useTimesheet } from '../../hooks';
import { checkEmployeeTimesheetEditable, mergeDaysArray } from '../../utils';
import { LineManagerComment } from '../line-manager-comment';

export const EmployeeButtons = ({ weekSettings, withDraft = false, employeeId }) => {
  const { timesheet, formRef } = useTimesheet();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const week = useSelector((state) => state.timesheet.days);

  const handleCancelClick = () => navigate(-1);

  const handleResetClick = () => {
    formRef.current.resetForm();
  };

  const submitForm = (isDraft) => {
    const timeSheetEntryPerActivities = formRef.current.values.timeSheetEntryPerActivities.map((entryPerActivity) => {
      const newObject = {
        ...entryPerActivity,
        activityId: entryPerActivity.activityCascader?.[1],
        comments: entryPerActivity.comments || '',
        timesheetEntry: timesheet?.id,
        dayActivityLinks: mergeDaysArray([
          week.days.map((day) => ({ date: day.theDate })),
          entryPerActivity.dayActivityLinks
        ]).map((day) => ({ ...day, timeSheetPerActivityId: entryPerActivity.id }))
      };

      return newObject;
    });

    const timesheetToSubmit = {
      ...timesheet,
      employeeId,
      weekId: week.weekId,
      isDraft,
      isMaster: false,
      timeSheetEntryPerActivities
    };

    if (!timesheet) {
      dispatch(timesheetActions.submitTimesheet(timesheetToSubmit));
    } else {
      dispatch(timesheetActions.patchTimesheet(timesheetToSubmit));
    }
  };

  const handleTimesheetSubmitWithDraft = () => {
    const timeSheetEntryPerActivities = formRef.current.values.timeSheetEntryPerActivities.map(
      ({ projectId, activityCascader }) => ({ projectId, activityCascader })
    );

    formRef.current.validateForm({ timeSheetEntryPerActivities }).then((errors) => {
      delete errors.days;

      if (Object.keys(errors).length) {
        return formRef.current.setTouched(errors);
      }
      submitForm(true);
    });
  };

  const handleTimesheetSubmit = () => {
    formRef.current.validateForm().then((errors) => {
      if (Object.keys(errors).length) {
        return formRef.current.setTouched(errors);
      }
      submitForm(false);
    });
  };

  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      {timesheet && timesheet.lineManagerComments && <LineManagerComment editable={false} />}
      {checkEmployeeTimesheetEditable(timesheet, weekSettings) && (
        <Row gutter={[16, 0]} justify={'end'}>
          <Col span={2}>
            <Popconfirm
              title="By cancelling, you will lose your changes, sure to cancel?"
              onConfirm={handleCancelClick}
            >
              <Button block danger ghost>
                Cancel
              </Button>
            </Popconfirm>
          </Col>
          <Col onClick={handleResetClick} span={2}>
            <Button type="default" block>
              Reset
            </Button>
          </Col>
          {withDraft && (
            <Col span={3}>
              <Popconfirm
                title="Keep in mind that this will only save your timesheet without submitting it for review, are you sure you want to save it as draft?"
                onConfirm={handleTimesheetSubmitWithDraft}
                placement="topRight"
              >
                <Button block ghost type="primary">
                  Save and submit later
                </Button>
              </Popconfirm>
            </Col>
          )}
          <Col span={3}>
            <Popconfirm
              title="Are you sure you want to submit this timesheet for manager review?"
              onConfirm={handleTimesheetSubmit}
              placement="topRight"
            >
              <Button block type="primary">
                Submit
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      )}
    </Space>
  );
};
