import { IconBan, IconCalendarOff, IconCheck, IconChecklist, IconReport, IconSettings } from '@tabler/icons';

export const renderNotificationAvatar = (notificationType, read) => {
  switch (notificationType) {
    case 'TIMESHEET_SUBMIT_TO_LINE_MANAGER':
      return <IconChecklist size={22} color={!read ? '#789637' : '#999999'} />;
    case 'TIMESHEET_SUBMIT_TO_PROJECT_DIRECTOR':
      return <IconReport size={22} color={!read ? '#789637' : '#999999'} />;
    case 'TIMESHEET_REVIEWED_BY_PROJECT_MANAGERS':
      return <IconCalendarOff size={22} color={!read ? '#789637' : '#999999'} />;
    case 'TIMESHEET_APPROVED_BY_LINE_MANAGER':
      return <IconCheck size={22} color={!read ? 'green' : '#999999'} />;
    case 'TIMESHEET_REJECTED_BY_LINE_MANAGER':
      return <IconBan size={22} color={!read ? 'red' : '#999999'} />;
    case 'TIMESHEET_SETTINGS_CHANGED':
      return <IconSettings size={22} color={!read ? '#789637' : '#999999'} />;
    default:
      break;
  }
};
