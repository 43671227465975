import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ConfigProvider } from 'antd';

import NavigationScroll from 'layout/navigation-scroll';
import { useSelector } from 'react-redux';
import Routes from 'routes';
import themes from 'themes';
import { useEffect } from 'react';

export const AppPrivate = () => {
  const customization = useSelector((state) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <ConfigProvider componentSize={'small'}>
          <CssBaseline />
          <NavigationScroll>
            <Routes />
          </NavigationScroll>
        </ConfigProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
