import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const notificationsServices = {
  getAllUnreadNotifications({ page, size }) {
    return axios.get(API_PATH + `/notification/all/unread?page=${page}&size=${size}`);
  },
  getAllReadNotifications({ page, size }) {
    return axios.get(API_PATH + `/notification/all/read?page=${page}&size=${size}`);
  },
  reviewNotification(payload) {
    return axios.put(API_PATH + '/notification/toggle', payload);
  },
  receiveNewNotification(payload) {
    return payload;
  }
};
