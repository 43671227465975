import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  all: []
};

export const { actions: managerDelegationActions, reducer: managerDelegationReducer } = createSlice({
  name: 'managerDelegation',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getManagerDelegationsAll(state) {
      state.loading = true;
    },
    getManagerDelegationsAllFulfilled(state, action) {
      state.all = action.payload;
    },
    postManagerDelegation(state) {
      state.loading = true;
    },
    postManagerDelegationFulfilled(state, action) {
      state.all = [...state.all, action.payload];
    },
    patchManagerDelegation(state) {
      state.loading = true;
    },
    patchManagerDelegationFulfilled(state, action) {
      const arr = [...state.all];
      const foundIndex = arr.findIndex((item) => item.id === action.payload.id);
      arr.splice(foundIndex, 1, action.payload);
      state.all = arr;
    }
  }
});
