import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { timesheetSettingsActions } from 'store/timesheet-settings';
import { EmployeeTimesheetWithDays, MasterFooter } from '../../components';
import { defaultTimesheetDaysSettings } from '../../consts';
import { getWeekInfo } from '../../utils';
import { pdComment } from './master.columns';

export const MasterTimesheet = ({ employeeId, contractType }) => {
  const dispatch = useDispatch();

  const weekSettings =
    useSelector((state) => state.timesheetSettings.masterTimesheetSettings) || defaultTimesheetDaysSettings;

  const singleApproval = useSelector((state) => state.appConfiguration.timesheetAutoReview);

  const dayTargetHours = contractType?.hoursPerDay || 0;

  const week = useSelector((state) => state.timesheet.days);
  const isInOvertime = week?.isInOvertime ?? false;

  const weekInfo = getWeekInfo(weekSettings?.timesheetSettingDays, isInOvertime, dayTargetHours);

  useEffect(() => {
    dispatch(timesheetSettingsActions.getMasterTimesheetSettings(employeeId));
  }, [employeeId]);

  return (
    <EmployeeTimesheetWithDays
      employeeId={employeeId}
      footer={<MasterFooter />}
      editable
      dayTargetHours={dayTargetHours}
      weekInfo={weekInfo}
      columns={{
        pdComment: pdComment
      }}
      pdCommentShown={(timesheet) => {
        return !singleApproval && timesheet && !timesheet.isDraft;
      }}
      filtersByStatus={false}
    />
  );
};
