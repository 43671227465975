import { ActivitySelect } from '../activity-select';
import { CommentCell } from '../comment-cell';
import { HoursCell } from '../hours-cell';

const activityCol = {
  render(_, __, index) {
    return <ActivitySelect index={index} />;
  }
};

const hoursCell = {
  onHeaderCell(dayError) {
    return {
      style:
        dayError && (!dayError.minHoursMatch || !dayError.maxHoursMatch)
          ? {
              backgroundColor: 'rgba(255, 0, 0, 0.1)'
            }
          : {}
    };
  },
  onCell(dayError) {
    return {
      style:
        dayError && (!dayError.minHoursMatch || !dayError.maxHoursMatch)
          ? {
              backgroundColor: 'rgba(255, 0, 0, 0.1)'
            }
          : {}
    };
  },
  render(entryIndex, dayIndex) {
    return <HoursCell entryIndex={entryIndex} dayIndex={dayIndex} />;
  }
};

const commentsProps = {
  width: 250,
  render(_, __, index) {
    return <CommentCell index={index} />;
  }
};

export const editableTimesheetCols = {
  activityProps: activityCol,
  dayProps: hoursCell,
  commentsProps: commentsProps
};
