import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  all: [],
  editingVat: null,
  adding: false
};

export const { actions: vatsActions, reducer: vatsReducer } = createSlice({
  name: 'vats',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getVatsAll(state) {
      state.loading = true;
    },
    getVatsAllFulfilled(state, action) {
      state.all = action.payload;
    },
    setAddingVat(state, { payload }) {
      state.adding = payload;
    },
    postVat(state) {
      state.loading = true;
    },
    postVatFulfilled(state, action) {
      state.all = [...state.all, action.payload];
      state.adding = false;
    },
    setEditingVat(state, { payload }) {
      state.editingVat = payload;
    },
    patchVat(state) {
      state.loading = true;
    },
    patchVatFulfilled(state, action) {
      const arr = [...state.all];
      const foundIndex = arr.findIndex((item) => item.id === action.payload.id);
      arr.splice(foundIndex, 1, action.payload);
      state.editingVat = null;
      state.all = arr;
    },
    deleteVat: (state) => {
      state.loading = true;
    },
    deleteVatFulfilled(state, action) {
      const updatedVatList = state.all.filter((vat) => vat.id !== action.payload);
      return {
        ...state,
        all: [...updatedVatList]
      };
    }
  }
});
