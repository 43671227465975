import { Weeks } from 'views/timesheet/consts';

export const checkWeek = (currentWeekId, notificationContent) => {
  if (currentWeekId - notificationContent.weekId === 1) {
    const lastWeek = Weeks.LAST_WEEK;
    return lastWeek;
  } else if (currentWeekId - notificationContent.weekId === 0) {
    const currentWeek = Weeks.CURRENT_WEEK;
    return currentWeek;
  } else {
    const nextWeek = Weeks.NEXT_WEEK;
    return nextWeek;
  }
};
