import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { pendingEmployeeServices } from './pending-employees.services';
import { pendingEmployeeActions } from './pending-employees.slice';

export function* pendingEmployeeRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getPendingEmployees',
      actions: pendingEmployeeActions,
      api: {
        method: pendingEmployeeServices.getPendingEmployees,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'registerEmployee',
      actions: pendingEmployeeActions,
      api: {
        method: pendingEmployeeServices.registerEmployee,
        callback: (res) => {
          return { data: res.data, nav: res.nav };
        }
      }
    }),
    fetchSaga({
      pattern: 'disactivateEmployee',
      actions: pendingEmployeeActions,
      api: {
        method: pendingEmployeeServices.disactivateEmployee,
        callback: (res) => {
          return { data: res.data, nav: res.nav };
        }
      }
    })
  ]);
}
