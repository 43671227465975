import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { timesheetActions } from 'store/time-sheet/time-sheet.slice';

export const useFetchWeekByWeekId = (employeeId, weekId) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (employeeId && weekId) {
      dispatch(timesheetActions.getDaysByWeekId({ weekId, employeeId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekId, employeeId]);
};
