import { Checkbox, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { caslRolesPrivilegesActions } from 'store/casl-roles-privileges/casl-roles-privileges.slice';
import { checkCheckedOneRoleAllGroupPermissions } from '../utils/check-checked-checkbox';
import { checkIndeterminateOneRoleAllGroupPermissions } from '../utils/check-indeterminate-checkbox';

const OneRoleAllGroupPermissionsCheckbox = ({ groupKeys, record, data }) => {
  const dispatch = useDispatch();

  // checkbox to dispatch all privileges in all objects for one role
  const handleAllObjectsSelect = (evt) => {
    let finalObjects = [];

    const allChecked = checkCheckedOneRoleAllGroupPermissions(record.permissions, groupKeys);

    const initialValues = {};
    groupKeys.forEach(
      (group) =>
        (initialValues[group.id] =
          data?.permissions.find((p) => p.object === group.id)?.privileges.map((priv) => priv.privilege) || null)
    );

    if (!allChecked) {
      groupKeys.map((group) => {
        group.permissions.map((per) => {
          finalObjects.push({
            object: group.id,
            caslRoleId: record.role,
            privilege: per.privilege
          });
        });
      });
      let finalValues = finalObjects.filter((x) => !initialValues[x.object]?.includes(x.privilege));
      dispatch(caslRolesPrivilegesActions.postCaslRolesPrivileges(finalValues));
    } else {
      let relationIds = [];
      groupKeys.map((group) => {
        const relationid = data?.permissions.find((p) => p.object === group.id)?.privileges.map((pr) => pr.id);
        relationIds.push(relationid);
      });
      dispatch(caslRolesPrivilegesActions.deleteCaslRolesPrivilege(relationIds.flat()));
    }
  };
  return (
    <Checkbox
      onChange={handleAllObjectsSelect}
      disabled={record.caslRoleName === 'ROLE_SUPERADMIN' && groupKeys.find((gk) => gk.id === 'permissionSettings')}
      checked={checkCheckedOneRoleAllGroupPermissions(record.permissions, groupKeys)}
      indeterminate={checkIndeterminateOneRoleAllGroupPermissions(record.permissions, groupKeys)}
    >
      <Typography.Text>Select All</Typography.Text>
    </Checkbox>
  );
};

export default OneRoleAllGroupPermissionsCheckbox;
