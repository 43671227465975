import { Box } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
// assets
import { Space } from 'antd';
import PropTypes from 'prop-types';
// project imports
import LogoSection from '../logo-section';
import { AdminSettingsButton } from './buttons/app-permissions';
import { AppSettingsButton } from './buttons/app-settings';
import { CurrencyExchangeButton } from './buttons/currency-exchange';
import { MenuToggleButton } from './buttons/menu-toggle';
import NotificationButton from './buttons/notifications';
import { SyncEmployeesButton } from './buttons/sync-employees';
import ProfileSection from './profile-section';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 220,
          display: 'flex',
          [theme.breakpoints.down('xs')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <MenuToggleButton handleLeftDrawerToggle={handleLeftDrawerToggle} />
      </Box>

      {/* header search */}
      {/* <SearchSection /> */}

      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      {/* notification & profile */}

      <Space direction="horizontal" size="middle">
        <CurrencyExchangeButton />
        <AdminSettingsButton />
        <AppSettingsButton />
        <SyncEmployeesButton />
        <NotificationButton />
        <ProfileSection />
      </Space>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;
