import OBJECTS from 'casl/objects';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

export const useHeaderHook = () => {
  const navigate = useNavigate();
  const CASL_OBJECTS = OBJECTS({});
  const theme = useTheme();

  return { navigate, CASL_OBJECTS, theme };
};
