import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { currentUserActions } from 'store/current-user/current-user.slice';
import useAuthHook from './useAuthHook';

const useCASHook = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();

  const { sessionValid, ticketValid, validatingTicket, validatingSession } = useAuthHook();

  const searchIncludesTicket = search.includes('?ticket');

  const navigate = useNavigate();

  useEffect(() => {
    if (!validatingTicket && !validatingSession && sessionValid) {
      dispatch(currentUserActions.getUserInfo());
      return;
    }
  }, [sessionValid, ticketValid]);

  useEffect(() => {
    if (searchIncludesTicket && sessionValid) {
      navigate('/');
    }
  }, [searchIncludesTicket, sessionValid, ticketValid]);
};

export default useCASHook;
