import PropTypes from 'prop-types';

// material-ui
import { Box, Drawer, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// third-party
import { BrowserView, MobileView } from 'react-device-detect';
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { drawerWidth } from 'store/constant';
import { customizationActions } from 'store/customization';
import MenuCard from './menu-card';
import MenuList from './menu-list';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const theme = useTheme();
  const matchUpXl = useMediaQuery(theme.breakpoints.up('xl'));
  const dispatch = useDispatch();
  const leftDrawerOpened = useSelector((state) => state.customization.opened);

  useEffect(() => {
    dispatch(customizationActions.setMenu(matchUpXl));
  }, [matchUpXl]);

  const drawer = (
    <>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={{
            height: 'calc(100vh - 56px)',
            paddingLeft: '0px',
            paddingRight: '0px'
          }}
        >
          <MenuList />
          <MenuCard />
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList />
        </Box>
      </MobileView>
    </>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpXl ? drawerWidth : 'auto' }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant={matchUpXl ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            background: 'theme.palette.background.default',
            color: theme.palette.text.primary,
            borderRight: 'none',
            zIndex: 99,
            top: 55
          }
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default Sidebar;
