import { mergeDaysArray } from './mergeDaysArray';

export const getTimesheetDisabledActivities = (timesheet, weekInfo, isInOvertime) => {
  return !isInOvertime
    ? timesheet?.timeSheetEntryPerActivities
        .map((entryPerActivity) => ({
          ...entryPerActivity,
          dayActivityLinks: mergeDaysArray([entryPerActivity.dayActivityLinks, weekInfo])
        }))
        .filter((entryPerActivity) => entryPerActivity.dayActivityLinks.some((day) => !day.isOpen && day.manHours))
        .map((entryPerActivity) => entryPerActivity.id) || []
    : [];
};
