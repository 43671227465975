import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const invoiceManagementServices = {
  getAllInvoicesByProjectId(projectId) {
    return axios.get(API_PATH + `/invoice/project?projectId=${projectId}`);
  },
  getAllPending() {
    return axios.get(API_PATH + `/invoice/all/pending`);
  },
  submitNewInvoice(payload) {
    return axios.post(API_PATH + '/invoice/post', payload);
  },
  patchInvoice(payload) {
    return axios.patch(API_PATH + '/invoice/patch', payload);
  },
  changeInvoicedDueDates(payload) {
    return axios.patch(API_PATH + '/invoice/changeDates', payload);
  },
  deleteInvoice(invoice) {
    return axios.delete(API_PATH + '/invoice/delete', {
      data: invoice
    });
  },
  projectDirectorDecision(payload) {
    return axios.put(API_PATH + '/invoice/accept', payload);
  },
  patchInvoiceStatusChange(status) {
    return status;
  }
};
