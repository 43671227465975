import rootReducer from './rootReducer';

import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';

function logger({ getState }) {
  return (next) => (action) => {
    console.log('will dispatch', action);

    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action);

    console.log('state after dispatch', getState());

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue;
  };
}

const saga = createSagaMiddleware();
const middlewares = window.env.NODE_ENV === 'development' ? [saga, logger] : [saga];
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
  devTools: window.env.NODE_ENV === 'development'
});
const persister = 'Free';

saga.run(rootSaga);

export { store, persister };
