import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { caslRolesPrivilegesServices } from './casl-roles-privileges.services';
import { caslRolesPrivilegesActions } from './casl-roles-privileges.slice';

export function* caslRolesPrivilegesRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getCaslRolesPrivilegesAll',
      actions: caslRolesPrivilegesActions,
      api: {
        method: caslRolesPrivilegesServices.getCaslRolesPrivilegesAll,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postCaslRolesPrivileges',
      actions: caslRolesPrivilegesActions,
      api: {
        method: caslRolesPrivilegesServices.postCaslRolesPrivileges,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteCaslRolesPrivilege',
      actions: caslRolesPrivilegesActions,
      api: {
        method: caslRolesPrivilegesServices.deleteCaslRolesPrivilege,
        callback: (res) => res.data.data
      }
    })
  ]);
}
