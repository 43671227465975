import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const leaveRequestServices = {
  getLeaveRequestTypes() {
    return axios.get(API_PATH + '/leave/leaveType/all');
  },
  getMyBusinessTripRequests(id) {
    return axios.get(API_PATH + `/leave/getAllByTypeByEmployee/${id}`);
  },
  getMyUnpaidLeaveRequests(id) {
    return axios.get(API_PATH + `/leave/getAllByTypeByEmployee/${id}`);
  },
  getMyPaidLeaveRequests(id) {
    return axios.get(API_PATH + `/leave/getAllByTypeByEmployee/${id}`);
  },
  getHRLeaveRequestsAll() {
    return axios.get(API_PATH + '/leave/getAllApproved');
  },
  getHistoryOfRequestsByLineManager() {
    return axios.get(API_PATH + `/leave/historyByLineManager`);
  },
  getPendingLeaveRequestsByDirectManagerId() {
    return axios.get(API_PATH + '/leave/pendingLeavesByManagerId');
  },
  getPendingBusinessTripByWorkpackageManager() {
    return axios.get(API_PATH + '/leave/getAllPendingBusinessTripsByWorkPackageManager');
  },
  getHistoryBusinessTripByWorkpackageManager() {
    return axios.get(API_PATH + `/leave/historyBusinessTripsByWorkPackageManager`);
  },
  submitLeaveRequest(payload) {
    return {
      data: axios.post(API_PATH + '/leave', payload.data),
      nav: payload.nav
    };
  },
  patchLeaveRequest(payload) {
    return {
      data: axios.patch(API_PATH + '/leave/', payload.data),
      nav: payload.nav
    };
  },
  workpackageManagerDecision(payload) {
    return axios.post(API_PATH + `/leave/managerDecision/${payload.id}`, payload);
  },
  deleteHrRequestById(payload) {
    return axios.delete(API_PATH + `/leave`, { headers: {}, data: { id: payload } });
  }
};
