import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { currentUserActions } from 'store/current-user/current-user.slice';

const useAuthHook = () => {
  const dispatch = useDispatch();
  const sessionValid = useSelector((state) => state.currentUser.sessionValid);
  const ticketValid = useSelector((state) => state.currentUser.ticketValid);
  const validatingTicket = useSelector((state) => state.currentUser.validatingTicket);
  const validatingSession = useSelector((state) => state.currentUser.validatingSession);
  const location = useLocation();
  const { search } = useLocation();

  useEffect(() => {
    const searchIncludesTicket = search.includes('?ticket');
    if (!searchIncludesTicket) {
      dispatch(currentUserActions.validateSession());
    }
  }, [location]);

  return { sessionValid, ticketValid, validatingTicket, validatingSession };
};

export default useAuthHook;
