// assets
import { IconCalendarOff, IconHourglass, IconRotateClockwise2 } from '@tabler/icons';
import Can from 'casl/can';
import * as CASL from 'casl/constants';
import OBJECTS from 'casl/objects';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { leaveRequestActions } from 'store/leave-request/leave-request.slice';
import { overtimeRequestActions } from 'store/overtime-request';
import { timesheetActions } from 'store/time-sheet/time-sheet.slice';

// constant
const icons = { IconRotateClockwise2, IconCalendarOff, IconHourglass };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const TeamAdministrationSpace = () => {
  const dispatch = useDispatch();
  const pendingTimesheets = useSelector((state) => state.timesheet.teamPendingTimesheets);
  const { directManager } = useSelector((state) => state.currentUser.responsibilities);

  const {
    leaveRequests: { directManagerPendingListOfRequests },
    overtimeRequests: { directManagerListOfRequestsPending }
  } = useSelector((state) => state);
  const overtimeAndLeavePendingRequests = [
    ...directManagerPendingListOfRequests,
    ...directManagerListOfRequestsPending
  ];

  const { moduleEnableLeaves, moduleEnableOvertime } = useSelector((state) => state.appConfiguration);

  const CASL_OBJECTS = OBJECTS({});

  useEffect(() => {
    if (directManager) {
      if (Can(CASL.CASL_READ, CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TIMEBOOKING.id)) {
        dispatch(timesheetActions.getTeamPendingTimeSheet());
      }
      if (Can(CASL.CASL_READ, CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TEAM_REQUESTS.id)) {
        if (moduleEnableOvertime) {
          dispatch(overtimeRequestActions.getAllPendingOvertimeRequestsByManagerId());
        }
        if (moduleEnableLeaves) {
          dispatch(leaveRequestActions.getPendingLeaveRequestsByDirectManagerId());
        }
      }
    }
  }, []);

  return directManager
    ? {
        id: CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TIMEBOOKING.id,
        title: 'My Team Administration',
        type: 'group',
        children: [
          ...(Can(CASL.CASL_READ, CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TIMEBOOKING.id)
            ? [
                {
                  id: 'team-timesheet-requests',
                  title: 'Timebooking',
                  type: 'collapse',
                  badge: pendingTimesheets.length,
                  icon: icons.IconCalendarOff,
                  children: [
                    {
                      id: 'pending',
                      title: 'Pending Requests',
                      type: 'item',
                      badge: pendingTimesheets.length,
                      url: CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TIMEBOOKING.path + '/pending',
                      icon: icons.IconHourglass,
                      breadcrumbs: false
                    }
                  ]
                }
              ]
            : []),
          ...(Can(CASL.CASL_READ, CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TEAM_REQUESTS.id)
            ? [
                {
                  id: CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TEAM_REQUESTS.id,
                  title: 'Team Requests',
                  type: 'collapse',
                  icon: icons.IconCalendarOff,
                  badge: overtimeAndLeavePendingRequests.length,
                  isHidden: !moduleEnableLeaves && !moduleEnableOvertime,
                  children: [
                    {
                      id: 'pending',
                      title: 'Pending Requests',
                      type: 'item',
                      badge: overtimeAndLeavePendingRequests.length,
                      url: CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TEAM_REQUESTS.path + '/pending',
                      icon: icons.IconHourglass,
                      breadcrumbs: false
                    },

                    {
                      id: 'history',
                      title: 'Requests History',
                      type: 'item',
                      url: CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TEAM_REQUESTS.path + '/history',
                      icon: icons.IconRotateClockwise2,
                      breadcrumbs: false
                    }
                  ]
                },
                {
                  id: 'team-leave-requests',
                  title: 'Request Details History',
                  type: 'hidden',
                  url: '/my-team-administration/team-leave-requests/leave-request-details',
                  icon: icons.IconCheckupList,
                  breadcrumbs: false
                }
              ]
            : [])
        ]
      }
    : {
        id: CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TIMEBOOKING.id,
        title: 'My Team Administration',
        type: 'group',
        children: []
      };
};

export default TeamAdministrationSpace;
