import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { store } from 'store';

const uniqueRoles = [
  {
    name: 'ROLE_SUPERADMIN',
    withActions: false,
    props: {
      type: 'danger',
      strong: true,
      style: {
        fontWeight: 700
      }
    }
  },
  {
    name: 'ROLE_TIMESHEET_USER',
    withActions: false,
    props: {
      strong: true,
      style: {
        color: '#1890ff'
      }
    }
  }
];

const initialState = {
  loading: false,
  error: false,
  all: []
};

export const selectEmployeesByRole = (state, roleId) => {
  return state.caslRoles.all.find((ro) => ro.caslRoleId === +roleId).employees;
};

export const selectRolesByEmployeeId = (employeeId) => {
  const roles = store.getState().caslRoles?.all.filter((ro) => ro.employees.find((emp) => emp.id === +employeeId));
  return roles;
};

const sortRoles = (roles) => {
  const payloadUniqueRoles = roles
    .reduce((uniqueItems, a) => {
      const uniqueRole = uniqueRoles.find((role) => role.name === a.caslRoleName);
      if (uniqueRole) {
        return [...uniqueItems, { ...a, ...uniqueRole }];
      }
      return uniqueItems;
    }, [])
    .sort((_, b) => {
      return b.name === 'ROLE_SUPERADMIN' ? 1 : -1;
    });
  return [
    ...payloadUniqueRoles,
    ...roles
      .filter((a) => !uniqueRoles.some((role) => role.name === a.caslRoleName))
      .sort((a, b) => a.caslRoleName.localeCompare(b.caslRoleName))
  ];
};

export const { actions: caslRolesActions, reducer: caslRolesReducer } = createSlice({
  name: 'caslRoles',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getCaslRolesAll(state) {
      state.loading = true;
    },
    getCaslRolesAllFulfilled(state, action) {
      state.all = sortRoles(action.payload);
    },
    postCaslRole(state) {
      state.loading = true;
    },
    postCaslRoleFulfilled(state, action) {
      state.all = sortRoles([...state.all, action.payload]);
    },
    patchCaslRoleName(state) {
      state.loading = true;
    },
    patchCaslRoleNameFulfilled(state, action) {
      const currentRoles = state.all.slice();
      const foundIndex = currentRoles.findIndex((item) => item.caslRoleId === action.payload.caslRoleId);
      currentRoles[foundIndex] = action.payload;
      state.all = sortRoles(currentRoles);
    },
    patchCaslRoleEmployees(state) {
      state.loading = true;
    },
    patchCaslRoleEmployeesFulfilled(state, action) {
      const arr = [...state.all];
      const foundIndex = arr.findIndex((item) => item.caslRoleId === action.payload.caslRoleId);
      arr.splice(foundIndex, 1, action.payload);
      state.all = arr;
    },
    patchEmployeeCaslRoles(state) {
      state.loading = true;
    },
    patchEmployeeCaslRolesFulfilled(state, action) {
      const response = action.payload;
      const roles = state.all;
      roles.map((rl) => {
        if (response.caslRoleIds.includes(rl.caslRoleId)) {
          if (!rl.employees.find((emp) => emp.id === response.employeeId)) {
            rl.employees = [...rl.employees, { id: response.employeeId, fullName: response.employeeFullName }];
          }
        } else {
          rl.employees = rl.employees.filter((r) => r.id !== response.employeeId);
        }
      });

      state.all = roles;
    },
    deleteCaslRole: (state) => {
      state.loading = true;
    },
    deleteCaslRoleFulfilled(state, action) {
      const updatedCaslRoles = state.all.filter((role) => role.caslRoleId !== action.payload);

      return {
        ...state,
        all: [...updatedCaslRoles]
      };
    }
  }
});
