import config from 'config';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: [], // for active default menu
  collapseSelected: null,
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  opened: true
};

export const { actions: customizationActions, reducer: customizationReducer } = createSlice({
  name: 'customization',
  initialState,
  reducers: {
    menuOpen: (state, action) => {
      const id = action.payload;
      state.isOpen = [id];
    },
    setCollapse: (state, action) => {
      const item = action.payload?.id;
      state.collapseSelected = item;
    },
    setMenu: (state, action) => {
      state.opened = action.payload;
    },
    setFontFamily: (state, action) => {
      return {
        ...state,
        fontFamily: action.fontFamily
      };
    },
    setBorderRadius: (state, action) => {
      state.borderRadius = action.borderRadius;
    }
  }
});
