import { DayHeader } from '../components';

export const getDayColumns = (options = {}, weekInfo, days, errors) => {
  return weekInfo.map((weekDay, index) => ({
    title: <DayHeader weekDay={weekDay} days={days} index={index} />,
    align: 'center',
    dataIndex: 'dayActivityLinks',
    ...options,
    onHeaderCell() {
      const dayError = errors?.days?.[index];

      if (options.onHeaderCell) return options.onHeaderCell(dayError);
    },
    onCell() {
      const dayError = errors?.days?.[index];
      if (options.onCell) return options.onCell(dayError);
    },
    render(days, __, entryIndex) {
      if (options.render) return options.render(entryIndex, index, days[index], weekInfo);
    }
  }));
};
