import { DecisionButtons } from '../decisition-buttons';
import { LineManagerComment } from '../line-manager-comment';

export const LineManagerFooter = ({ navigateBackAfterDecision, isMasterApproval }) => {
  return (
    <LineManagerComment editable isMasterApproval={isMasterApproval}>
      <DecisionButtons navigateBackAfterDecision={navigateBackAfterDecision} />
    </LineManagerComment>
  );
};
