import { all, put, takeLatest } from 'redux-saga/effects';
import { projectActions } from 'store/project/project.slice';
import { workpackageActions } from 'store/workpackage/workpackage.slice';
import { fetchSaga } from 'utils/fetch-saga';
import { projectTeamMemberServices } from './project-team-employees.services';
import { projectTeamMemberActions } from './project-team-employees.slice';

export function* projectTeamMemberRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getProjectTeamMembersByProjectId',
      actions: projectTeamMemberActions,
      api: {
        method: projectTeamMemberServices.getProjectTeamMembersByProjectId,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postProjectTeamMember',
      actions: projectTeamMemberActions,
      api: {
        method: projectTeamMemberServices.postProjectTeamMember,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'removeAllTeamMembers',
      actions: projectTeamMemberActions,
      api: {
        method: projectTeamMemberServices.removeAllTeamMembers,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deactivateProjectTeamMember',
      actions: projectTeamMemberActions,
      api: {
        method: projectTeamMemberServices.deactivateProjectTeamMember,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteProjectTeamMembers',
      actions: projectTeamMemberActions,
      api: {
        method: projectTeamMemberServices.deleteProjectTeamMembers,
        callback: (res) => res.data.data
      }
    }),
    takeLatest(projectActions.patchProjectFulfilled.type, dispatchReloadProjectTeamMembersOnProjectPatch),
    takeLatest(
      [workpackageActions.patchWorkpackageFulfilled.type, workpackageActions.postWorkpackageFulfilled.type],
      dispatchReloadProjectTeamMembersOnProjectPatch
    ),
    takeLatest(
      projectTeamMemberActions.postProjectTeamMemberFulfilled.type,
      dispatchReloadProjectTeamMembersOnTeamChange
    )
  ]);
}

// project patch watcher for updating list of team members
function* dispatchReloadProjectTeamMembersOnProjectPatch(action) {
  const res = action.payload;
  res.projectDirector
    ? yield put(projectTeamMemberActions.getProjectTeamMembersByProjectId(res.id))
    : yield put(projectTeamMemberActions.getProjectTeamMembersByProjectId(res.project.id));
}

function* dispatchReloadProjectTeamMembersOnTeamChange() {
  yield put(projectTeamMemberActions.toggleManageEmployeesVisible());
}
