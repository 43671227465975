import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const currencyExchangeServices = {
  getExchanges() {
    return axios.get(API_PATH + '/conversion?sort=date');
  },
  postExchange(exchange) {
    return axios.post(API_PATH + '/conversion', exchange);
  },
  patchExchange(exchange) {
    return axios.patch(API_PATH + '/conversion', exchange);
  }
};

/* POST OBJECT
  {
    "date": "2021-12-06",
    "conversionRate": 12500,
    "fromCurrency": "http://172.30.5.18:8080/api/currency/1",
    "toCurrency": "http://172.30.5.18:8080/api/currency/1"
    }
*/

/* PATCH OBJECT
  {
    "id": 2,
    "date": "2021-12-06",
    "conversionRate": 12500,
    "fromCurrency": "http://172.30.5.18:8080/api/currency/1",
    "toCurrency": "http://172.30.5.18:8080/api/currency/1"
    }
*/
