import Can from 'casl/can';
import * as CASL from 'casl/constants';
import OBJECTS from 'casl/objects';
import { format } from 'date-fns';
import { put } from 'redux-saga/effects';
import { leaveRequestActions } from 'store/leave-request/leave-request.slice';
import { timesheetActions } from 'store/time-sheet/time-sheet.slice';

export const appRelatedData = (moduleEnableLeaves) => {
  const CASL_OBJECTS = OBJECTS({});
  const date = format(new Date(), 'yyyy-MM-dd');
  return [
    [
      Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_TIME_BOOKING.id),
      Can(CASL.CASL_READ, CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TIMEBOOKING.id),
      Can(CASL.CASL_READ, CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.GENERAL_TIMEBOOKING_MASTER_APPROVAL.id),
      Can(CASL.CASL_READ, CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.GENERAL_TIMEBOOKING_IMPERSONATE.id)
    ].includes(true) && put(timesheetActions.getDaysByDate({ date })),
    ...(moduleEnableLeaves ? [put(leaveRequestActions.getLeaveRequestTypes())] : [])
  ];
};
