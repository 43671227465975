import { compareAsc, parseISO } from 'date-fns';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { projectActions } from 'store/project/project.slice';
import { timesheetServices } from 'store/time-sheet/time-sheet.services';
import { timesheetActions } from 'store/time-sheet/time-sheet.slice';

export const useTimesheetInfo = (employeeId, filtersByStatus = true) => {
  const dispatch = useDispatch();

  const week = useSelector((state) => state.timesheet.days);

  const [timesheet, setTimesheetInfo] = useState(null);

  useEffect(() => {
    dispatch(projectActions.getProjectsForTimesheet(filtersByStatus));
  }, []);

  useEffect(() => {
    dispatch(timesheetActions.setLoading(true));
    (async () => {
      if (employeeId && week) {
        try {
          const res = await timesheetServices.getTimeSheetByUserAndWeek({
            employeeId: employeeId,
            weekId: week.weekId
          });

          const timeSheet = res.data.data;
          if (timeSheet && !_.isEmpty(timeSheet) && !Array.isArray(timeSheet)) {
            timeSheet.timeSheetEntryPerActivities = timeSheet.timeSheetEntryPerActivities.map((e) => ({
              ...e,
              projectId: e.project.id,
              activityCascader: [e.subproject.id, e.activity.id],
              activityId: e.activityId || e.activity.id,
              dayActivityLinks: week.days
                ?.reduce((accumulator, fwd) => {
                  if (!accumulator.some((da) => da.date === fwd.theDate)) {
                    return [...accumulator, { date: fwd.theDate, manHours: 0 }];
                  }
                  return accumulator;
                }, e.dayActivityLinks)
                .sort((a, b) => compareAsc(parseISO(a.date), parseISO(b.date)))
            }));
            setTimesheetInfo(timeSheet);
          } else {
            setTimesheetInfo(null);
          }
        } catch (error) {
          console.log(error);
        } finally {
          dispatch(timesheetActions.setLoading(false));
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [week?.weekId, employeeId]);

  return timesheet;
};
