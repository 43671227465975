import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  invoicesByProject: [],
  pendingInvoices: [],
  newInvoice: null,
  changeInvoiceDatesModalOpen: null,
  selectedProject: null
};

export const selectInvoiceById = (state, invoiceId) => {
  const pi = state.projectInvoices.invoicesByProject.find((sp) => sp.id === invoiceId);
  return pi;
};

export const { actions: invoiceManagementActions, reducer: invoiceManagementReducer } = createSlice({
  name: 'invoiceManagement',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    setSelectedProject(state, action) {
      state.selectedProject = action.payload;
    },
    setChangeInvoiceDatesModalOpen(state, action) {
      state.changeInvoiceDatesModalOpen = action.payload;
    },
    getAllInvoicesByProjectId(state) {
      state.loading = true;
    },
    getAllInvoicesByProjectIdFulfilled(state, action) {
      let invoices = [...action.payload];
      state.invoicesByProject = invoices.sort((a, b) => a.invoiceCode.localeCompare(b.invoiceCode));
    },
    getAllPending(state) {
      state.loading = true;
    },
    getAllPendingFulfilled(state, action) {
      let invoices = [...action.payload];
      state.pendingInvoices = invoices.sort((a, b) => a.invoiceCode.localeCompare(b.invoiceCode));
    },
    submitNewInvoice(state) {
      state.loading = true;
    },
    submitNewInvoiceFulfilled(state, action) {
      state.invoicesByProject = [...state.invoicesByProject, action.payload];
      state.pendingInvoices = [...state.pendingInvoices, action.payload];
    },
    patchInvoice(state) {
      state.loading = true;
    },
    patchInvoiceFulfilled(state, action) {
      let invoices = [...state.invoicesByProject];
      const index = invoices.findIndex((item) => item.id === action.payload.id);
      invoices.splice(index, 1, action.payload);
      state.invoicesByProject = [...invoices];
    },
    changeInvoicedDueDates(state) {
      state.loading = true;
    },
    changeInvoicedDueDatesFulfilled(state, action) {
      let invoices = [...state.invoicesByProject];
      const index = invoices.findIndex((item) => item.id === action.payload.id);
      invoices.splice(index, 1, action.payload);
      state.invoicesByProject = [...invoices];
    },
    deleteInvoice(state) {
      state.loading = true;
    },
    deleteInvoiceFulfilled(state, action) {
      const updatedInvoices = state.invoicesByProject.filter((b) => b.id != action.payload);
      const updatedPending = (state.pendingInvoices = [
        ...state.pendingInvoices.filter((pi) => pi.id !== action.payload.id)
      ]);
      state.invoicesByProject = [...updatedInvoices];
      state.pendingInvoices = [...updatedPending];
    },
    projectDirectorDecision: (state) => {
      state.loading = true;
    },
    projectDirectorDecisionFulfilled(state, action) {
      let newInv = [...state.invoicesByProject];
      const index = newInv.findIndex((item) => item.id === action.payload.id);
      newInv.splice(index, 1, {
        ...action.payload
      });
      state.invoicesByProject = [...newInv];
      state.pendingInvoices = [...state.pendingInvoices.filter((pi) => pi.id !== action.payload.id)];
    },
    patchInvoiceStatusChange(state) {
      state.loading = true;
    },
    patchInvoiceStatusChangeFulfilled(state, action) {
      const invoices = [...state.invoicesByProject];
      const index = invoices.findIndex((item) => item.id === action.payload.invoiceId);
      const invoice = invoices[index];
      const paymentWorkpackageLinks = action.payload.paymentWorkpackageLinks;
      invoice.transactionStatus = action.payload.transactionStatus;
      invoice.remainedTotalAmount = action.payload.remainedTotalAmount;
      invoice.invoiceWorkpackageAmounts = invoice.invoiceWorkpackageAmounts.map((invoiceWorkpackageAmount) => {
        const paymentWorkpackageLink = paymentWorkpackageLinks.find(
          (pwl) => pwl.subprojectId === invoiceWorkpackageAmount.subproject.subprojectId
        );
        return {
          ...invoiceWorkpackageAmount,
          id: paymentWorkpackageLink.invoiceWorkpackageAmountId,
          remainedAmount: paymentWorkpackageLink.remainedAmount
        };
      });
      invoices.splice(index, 1, {
        ...invoice
      });
      state.invoicesByProject = invoices;
    }
  }
});
