import Can from 'casl/can';
import * as CASL from 'casl/constants';
import OBJECTS from 'casl/objects';
// project imports
import MainLayout from 'layout/main-layout';
import { lazy } from 'react';
import { useSelector } from 'react-redux';
import Loadable from 'ui-component/loadable';

const ProjectsDefault = Loadable(lazy(() => import('views/projects/default')));
const ProjectsDetails = Loadable(lazy(() => import('views/projects/details')));
const WorkpackagesDetails = Loadable(lazy(() => import('views/subprojects/default')));
const CreateBudgetPage = Loadable(
  lazy(() => import('views/subprojects/default/sub-project-budget/create-budget-page'))
);
const ListOfRequests = Loadable(lazy(() => import('views/leave-requests/workpackage-manager/leave-requests-list')));
const LeaveRequestDetails = Loadable(lazy(() => import('views/leave-requests/leave-request-details')));

const ProjectManagersTimesheetsLandingPage = Loadable(
  lazy(() => import('views/timesheet/project-managers-timesheets'))
);

const ProjectWorkpackageManagersDelegation = Loadable(
  lazy(() => import('views/delegate-project-workpackage-managers/index'))
);

const CreateInvoicePage = Loadable(lazy(() => import('views/invoice-management/create-invoice/create-invoice-page')));
const InvoiceDetailsPage = Loadable(lazy(() => import('views/invoice-management/invoice-details/index')));

// ==============================|| MAIN ROUTING ||============================== //
export default function ProjectRoutes() {
  const { projectDirector } = useSelector((state) => state.currentUser.responsibilities);
  const { timesheetAutoReview, moduleEnableLeaves, moduleEnableOvertime, moduleEnableProjectInvoices } = useSelector(
    (state) => state.appConfiguration
  );
  const employeeResponsibilities = useSelector((state) => state.currentUser.responsibilities);
  const CASL_OBJECTS = OBJECTS({});

  return {
    path: CASL_OBJECTS.PROJECT_SETTINGS_GROUP.childrenObjects.PROJECTS.path,
    element: <MainLayout />,
    children: [
      Can(CASL.CASL_READ, CASL_OBJECTS.PROJECT_SETTINGS_GROUP.childrenObjects.PROJECTS.id) && {
        index: true,
        element: <ProjectsDefault />
      },
      Can(CASL.CASL_READ, CASL_OBJECTS.PROJECT_SETTINGS_GROUP.childrenObjects.PROJECT_DETAILS.id) && {
        path: CASL_OBJECTS.PROJECT_SETTINGS_GROUP.childrenObjects.PROJECT_DETAILS.path + '/:id',
        element: <ProjectsDetails />
      },
      Can(CASL.CASL_READ, CASL_OBJECTS.WORKPACKAGES_GROUP.childrenObjects.WORKPACKAGE_DETAILS.id) && {
        path: CASL_OBJECTS.WORKPACKAGES_GROUP.childrenObjects.WORKPACKAGE_DETAILS.path + '/:workpackageId',
        element: <WorkpackagesDetails />
      },
      Can(CASL.CASL_READ, CASL_OBJECTS.WORKPACKAGES_GROUP.childrenObjects.WORKPACKAGE_BUDGET.id) && {
        path: CASL_OBJECTS.WORKPACKAGES_GROUP.childrenObjects.WORKPACKAGE_BUDGET.path,
        element: <CreateBudgetPage />
      },
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.INVOICE_MANAGEMENT_PERMISSIONS.childrenObjects.INVOICE_MANAGEMENT.id)
        ? [
            {
              path: '/projects/:projectId/invoice-details/:invoiceId',
              element: <InvoiceDetailsPage />,
              isHidden: !moduleEnableProjectInvoices
            },
            {
              path: `${CASL_OBJECTS.INVOICE_MANAGEMENT_PERMISSIONS.childrenObjects.INVOICE_MANAGEMENT.path}/:projectId/invoice/:invoiceId`,
              element: <CreateInvoicePage />,
              isHidden: !moduleEnableProjectInvoices
            }
          ]
        : []),
      ...(Can(CASL.CASL_UPDATE, CASL_OBJECTS.PROJECT_SETTINGS_GROUP.childrenObjects.DELEGATE_PROJECT_MANAGER.id)
        ? [
            {
              path: CASL_OBJECTS.PROJECT_SETTINGS_GROUP.childrenObjects.DELEGATE_PROJECT_MANAGER.path,
              element: <ProjectWorkpackageManagersDelegation />
            }
          ]
        : []),
      ...(employeeResponsibilities.workPackageManager
        ? [
            {
              path: CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.PM_LEAVE_REQUESTS_ALL.path,
              element: <ListOfRequests />,
              isHidden: !moduleEnableLeaves
            },
            {
              path: CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.PM_LEAVE_REQUESTS_DETAILS.path,
              element: <LeaveRequestDetails />,
              isHidden: !moduleEnableLeaves
            }
          ]
        : []),
      (Can(CASL.CASL_READ, CASL_OBJECTS.PROJECT_TEAM_GROUP.childrenObjects.PROJECT_MANAGER_TIMESHEETS.id) ||
        projectDirector) && {
        path: CASL_OBJECTS.PROJECT_TEAM_GROUP.childrenObjects.PROJECT_MANAGER_TIMESHEETS.path,
        element: <ProjectManagersTimesheetsLandingPage />,
        isHidden: timesheetAutoReview
      }
    ].filter((route) => !route.isHidden)
  };
}
