import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const projectServices = {
  getMyProjects() {
    return axios.get(API_PATH + '/project/myProjects');
  },
  getProjects() {
    return axios.get(API_PATH + '/project');
  },
  getProjectsForTimesheet(applyFilter) {
    return axios.get(API_PATH + `/project/retrieved/forTimesheet?applyFilter=${applyFilter}`);
  },
  getProjectsForBusinessTripRequests() {
    return axios.get(API_PATH + `/project/retrieved/forBusinessTrip`);
  },
  getLatestProjectCode() {
    return axios.get(API_PATH + '/project/getCode');
  },
  postProject(payload) {
    return {
      data: axios.post(API_PATH + '/project', payload.data),
      nav: payload.nav
    };
  },
  patchProject(payload) {
    return axios.patch(API_PATH + '/project', payload);
  },
  changeProjectStatus(payload) {
    return axios.post(API_PATH + '/project/projectChangeStatus/' + payload.projectId + '/' + payload.statusId);
  },
  deleteProject(payload) {
    return axios.delete(API_PATH + '/project/' + payload);
  },
  updateProjectDirectors(payload) {
    return payload;
  }
};
