import { WeekKeys, Weeks } from 'views/timesheet/consts';
import { generateTheDay } from './generateTheDay';

export const notificationsTypes = [
  {
    id: 'TIMESHEET_SUBMIT_TO_LINE_MANAGER',
    path: '/my-team-administration/team-timesheet-requests/approve-timesheet',
    locationState: (content) => {
      return {
        weekId: content.week.weekId,
        employeeId: content.employee.id,
        manager: true
      };
    }
  },
  {
    id: 'TIMESHEET_SUBMIT_TO_PROJECT_DIRECTOR',
    path: '/projects/project-timesheet-requests',
    locationState: (content) => {
      return {
        project: +content.projectId
      };
    }
  },
  {
    id: 'TIMESHEET_REVIEWED_BY_PROJECT_MANAGERS',
    path: '/my-team-administration/team-timesheet-requests/pending',
    locationState: () => {
      return {};
    }
  },
  {
    id: 'TIMESHEET_REJECTED_BY_LINE_MANAGER',
    path: '/my-personal-space/my-timebooking',
    locationState: (content, checkWeek, settings) => {
      return {};
    }
  },
  {
    id: 'TIMESHEET_APPROVED_BY_LINE_MANAGER',
    path: '/my-personal-space/my-timebooking',
    locationState: (content, checkWeek, settings) => {
      return {};
    }
  },
  { id: 'TO_USER', message: 'Your Timesheet has been reviewed' },
  { id: 'TO_ALL', message: 'New Notification' }
];
