import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  myTimesheets: [],
  listOfAllActivities: [],
  teamPendingTimesheets: [],
  projectTeamTimesheetsAll: [],
  days: null,
  userTimeSheetsForMaster: [],
  ISOweeks: [],
  timesheet: null,
  currentWeekId: ''
};

export const selectTimesheetByWeekId = (state, weekId) => {
  return state.timesheet.myTimesheets.find((ent) => ent.weekId === weekId);
};

export const selectTeamTimesheetsForPreviousWeek = (state, weekId) => {
  return state.teamPendingTimesheets.find((ent) => ent.weekId === weekId);
};

export const { actions: timesheetActions, reducer: timesheetReducer } = createSlice({
  name: 'timesheet',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    setDays(state, action) {
      state.days = action.payload;
    },
    submitTimesheet: (state) => {
      state.loading = true;
    },
    submitTimesheetFulfilled(state, action) {
      const addedObject = action.payload.data;
      state.timesheet = addedObject;
      if (!addedObject.isImpersonate) {
        const myTimesheets = state.myTimesheets;

        state.myTimesheets = [...myTimesheets, addedObject];
      }
    },
    deleteTimesheet: (state, action) => {
      state.loading = true;
    },
    deleteTimesheetFulfilled(state, action) {
      const finalTimesheets = state.userTimeSheetsForMaster.filter(
        (userTimesheet) => userTimesheet.id !== action.payload.data.data
      );
      state.userTimeSheetsForMaster = finalTimesheets;
    },
    getDaysByDate(state) {
      state.loading = true;
    },
    getDaysByDateFulfilled(state, action) {
      state.days = action.payload;
      state.currentWeekId = action.payload.weekId;
    },
    getMyTimeSheet(state) {
      state.loading = true;
    },
    getMyTimeSheetFulfilled(state, action) {
      state.myTimesheets = action.payload;
    },
    getTeamPendingTimeSheet(state) {
      state.loading = true;
    },
    getTeamPendingTimeSheetFulfilled(state, action) {
      state.teamPendingTimesheets = action.payload;
    },
    getProjectTeamTimeSheetsAll(state) {
      state.loading = true;
    },
    getProjectTeamTimeSheetsAllFulfilled(state, action) {
      state.projectTeamTimesheetsAll = action.payload;
    },
    setListOfAllActivities(state, action) {
      state.listOfAllActivities = action.payload;
    },
    setListOfSelectedActivities(state, { payload: activities }) {
      state.listOfAllActivities = state.listOfAllActivities.map((p) => {
        p.children.map((wp) => {
          wp.children.map((a) => {
            a.disabled = activities.some((activity) => activity[1] === a.value);
            return a;
          });
        });
        return p;
      });
    },
    getDaysByWeekId(state) {
      state.loading = true;
    },
    getDaysByWeekIdFulfilled(state, action) {
      state.days = action.payload;
    },
    getDaysByWeeksId(state, action) {
      state.loading = true;
    },
    getDaysByWeeksIdFulfilled(state, action) {
      state.ISOweeks = action.payload;
    },
    getTimeSheetByUserAndWeek(state) {
      state.loading = true;
    },
    getTimeSheetByUserAndWeekFulfilled(state, action) {
      state.days = action.payload;
    },
    approveTimeSheet(state) {
      state.loading = true;
    },
    approveTimeSheetFulfilled(state, action) {
      const decisionMadeTimesheet = action.payload;

      state.teamPendingTimesheets = state.teamPendingTimesheets.filter((item) => item.id !== decisionMadeTimesheet.id);
      const masterTimesheets = state.userTimeSheetsForMaster;
      const changedItemIndex = masterTimesheets.findIndex((timesheet) => timesheet.id === decisionMadeTimesheet.id);

      state.userTimeSheetsForMaster = [
        ...masterTimesheets.slice(0, changedItemIndex),
        decisionMadeTimesheet,
        ...masterTimesheets.slice(changedItemIndex + 1)
      ];
      state.timesheet = decisionMadeTimesheet;
    },
    patchTimesheet(state) {
      state.loading = true;
    },
    patchTimesheetFulfilled(state, action) {
      const response = action.payload.data;
      state.timesheet = response;
      if (!response.isImpersonate) {
        const foundIndex = state.myTimesheets.findIndex((item) => item.id === response.id);
        state.myTimesheets.splice(foundIndex, 1, response);
        state.myTimesheets = [...state.myTimesheets];
      }
    },
    getUserTimeSheetsForMaster(state) {
      state.loading = true;
    },
    getUserTimeSheetsForMasterFulfilled(state, action) {
      state.userTimeSheetsForMaster = action.payload.sort((a, b) => b.week.weekId - a.week.weekId);
    },
    resetUserTimeSheets(state, action) {
      state.userTimeSheetsForMaster = [];
      state.days = null;
    },
    addNewTeamMemberTimesheet(state, action) {
      const newTimesheet = action.payload;
      const teamPendingTimesheets = state.teamPendingTimesheets;

      state.teamPendingTimesheets = [...teamPendingTimesheets, newTimesheet];
    },
    updateMyTimesheet(state, action) {
      const response = action.payload;
      // ask abount if statement
      if (!response.isImpersonate) {
        const foundIndex = state.myTimesheets.findIndex((item) => item.id === response.id);
        state.myTimesheets.splice(foundIndex, 1, response);
        state.myTimesheets = [...state.myTimesheets];
      }
    }
  }
});
