import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  all: []
};

export const { actions: entityTypeActions, reducer: entityTypeReducer } = createSlice({
  name: 'entityType',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getEntityTypes: (state) => {
      state.loading = true;
    },
    getEntityTypesFulfilled(state, action) {
      state.all = action.payload;
    },
    postEntityTypes(state) {
      state.loading = true;
    },
    postEntityTypesFulfilled(state, payload) {}
  }
});
