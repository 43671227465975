import { Empty, Table } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getColumns } from './columns';
import { getCurrency } from 'views/currency-exchange/utils';
import useCurrenciesHook from '../../utils/hooks/useCurrenciesHook';

export const ExchangeTable = ({ selectedCurrencies, setSelectedCurrencies }) => {
  const {
    currencyExchanges: { exchanges }
  } = useSelector((state) => state);

  const { currencies, loading } = useCurrenciesHook();
  const [activeCurrency, setActiveCurrency] = useState(null);

  const prevCurrency = useRef();

  useEffect(() => {
    if (prevCurrency.current) {
      const prevCurrencyIndex = selectedCurrencies.indexOf(prevCurrency.current);

      setSelectedCurrencies([
        ...selectedCurrencies.slice(0, prevCurrencyIndex),
        activeCurrency,
        ...selectedCurrencies.slice(prevCurrencyIndex + 1)
      ]);
      return;
    }
    setSelectedCurrencies([...selectedCurrencies, activeCurrency]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCurrency]);

  if (!currencies) return null;

  const showingExchanges = (exchanges?.[activeCurrency] || []).slice().sort((a, b) => {
    const aCurrency = getCurrency(a.toCurrency, currencies);
    const bCurrency = getCurrency(b.toCurrency, currencies);

    if (aCurrency.name > bCurrency.name) {
      return 1;
    } else if (aCurrency.name < bCurrency.name) {
      return -1;
    }
    return 0;
  });

  return (
    <Table
      loading={loading}
      rowKey={'id'}
      columns={getColumns(
        currencies,
        currencies.filter((currency) => !selectedCurrencies.includes(currency.id)),
        activeCurrency,
        (value) => {
          prevCurrency.current = activeCurrency;
          setActiveCurrency(value);
        }
      )}
      dataSource={showingExchanges}
      pagination={false}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              !activeCurrency ? (
                <strong>Select a currency to show exchange rates</strong>
              ) : (
                <strong>No data for this currency</strong>
              )
            }
          />
        )
      }}
    />
  );
};

ExchangeTable.propTypes = {
  selectedCurrencies: PropTypes.array,
  setSelectedCurrencies: PropTypes.func
};
