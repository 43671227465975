import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  loading: false,
  error: false,
  loadingForProjects: false,
  loadingForWorkpackages: false,
  allProjectManagers: [],
  allWorkpackageManagers: [],
  allProjects: [],
  allWorkpackages: []
};
export const {
  actions: projectWorpakacageManagerDelegationActions,
  reducer: projectWorpakacageManagerDelegationReducer
} = createSlice({
  name: 'projectWorpakacageManagerDelegation',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    resetSlice(state, action) {
      state.allProjects = [];
      state.allWorkpackages = [];
    },
    getAllProjectManagers(state) {
      state.loading = true;
      state.loadingForProjects = true;
    },
    getAllProjectManagersFulfilled(state, action) {
      state.allProjectManagers = action.payload;
      state.loadingForProjects = false;
    },
    getAllWorkpackageManagers(state) {
      state.loading = true;
      state.loadingForWorkpackages = true;
    },
    getAllWorkpackageManagersFulfilled(state, action) {
      state.allWorkpackageManagers = action.payload;
      state.loadingForWorkpackages = false;
    },
    getAllProjectsByManagerId(state) {
      state.loading = true;
      state.loadingForProjects = true;
    },
    getAllProjectsByManagerIdFulfilled(state, action) {
      state.allProjects = action.payload;
      state.loadingForProjects = false;
    },
    getAllWorkpackagesByManagerId(state) {
      state.loading = true;
      state.loadingForWorkpackages = true;
    },
    getAllWorkpackagesByManagerIdFulfilled(state, action) {
      state.allWorkpackages = action.payload;
      state.loadingForWorkpackages = false;
    },
    permanentlyDelegateManager(state) {
      state.loading = true;
    },
    permanentlyDelegateManagerFulfilled(state, action) {}
  }
});
