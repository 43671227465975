import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  all: [],
  listActiveTab: 'active'
};

export const { actions: salariesActions, reducer: salariesReducer } = createSlice({
  name: 'salaries',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getSalariesAll(state) {
      state.loading = true;
    },
    getSalariesAllFulfilled(state, action) {
      state.all = action.payload;
    },
    postSalary(state) {
      state.loading = true;
    },
    postSalaryFulfilled(state, action) {
      state.all = [
        ...state.all,
        {
          employee: {
            id: action.payload.id,
            fullName: action.payload.fullName,
            employeeCode: action.payload.employeeCode
          },
          isActiveEmployee: 1,
          netSalary: 0,
          additionalYearlyCost: 0,
          numberPossibleYearlyBonus: 0,
          totalPaidLeave: 0
        }
      ];
    },
    patchSalary(state) {
      state.loading = true;
    },
    changeActiveTabList(state, action) {
      state.listActiveTab = action.payload;
    },
    patchSalaryFulfilled(state, action) {
      const arr = [...state.all];
      const foundIndex = arr.findIndex((item) => item.employee.id === action.payload.employee.id);
      const newSalary = { ...action.payload };
      arr.splice(foundIndex, 1, newSalary);
      state.all = arr;
    }
  }
});
