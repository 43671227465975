import { workpackageProgressServices } from './subproject-progress.services';
import { fetchSaga } from 'utils/fetch-saga';
import { all } from 'redux-saga/effects';
import { workpackageProgressActions } from './subproject-progress.slice';

export function* workpackageProgressRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getProgressByworkpackageId',
      actions: workpackageProgressActions,
      api: {
        method: workpackageProgressServices.getProgressByWorkpackageId,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'createProgress',
      actions: workpackageProgressActions,
      api: {
        method: workpackageProgressServices.createProgress,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchProgress',
      actions: workpackageProgressActions,
      api: {
        method: workpackageProgressServices.patchProgress,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteProgress',
      actions: workpackageProgressActions,
      api: {
        method: workpackageProgressServices.deleteProgress,
        callback: (res) => res.data.data
      }
    })
  ]);
}
