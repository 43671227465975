// project imports
import MainLayout from 'layout/main-layout';
import { lazy } from 'react';
import Loadable from 'ui-component/loadable';
import Can from 'casl/can';
import * as CASL from 'casl/constants';
import OBJECTS from 'casl/objects';
import { useSelector } from 'react-redux';

// personal space routing
const DirectManagerRequestsList = Loadable(
  lazy(() => import('views/leave-requests/direct-manager/manager-requests-table'))
);

const LeaveRequestDetails = Loadable(lazy(() => import('views/leave-requests/leave-request-details')));
const MyTeamPendingTimesheetRequests = Loadable(lazy(() => import('views/timesheet/team-administration')));
const ManagerTimesheet = Loadable(
  lazy(() =>
    import('views/timesheet/timesheet').then((file) => ({
      default: file.ManagerTimesheet
    }))
  )
);
// ==============================|| MAIN ROUTING ||============================== //

export default function TeamAdministrationRoutes() {
  const CASL_OBJECTS = OBJECTS({});
  const { directManager } = useSelector((state) => state.currentUser.responsibilities);

  const { timesheetAutoReview, moduleEnableLeaves, moduleEnableOvertime } = useSelector(
    (state) => state.appConfiguration
  );
  return {
    path: '/my-team-administration',
    element: <MainLayout />,
    children: [
      directManager &&
        Can(CASL.CASL_READ, CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TEAM_REQUESTS.id) && {
          path: CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TEAM_REQUESTS.path + '/history',
          element: <DirectManagerRequestsList />,
          isHidden: !moduleEnableLeaves && !moduleEnableOvertime
        },
      directManager &&
        Can(CASL.CASL_READ, CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TEAM_REQUESTS.id) && {
          path: CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TEAM_REQUESTS.path + '/pending',
          element: <DirectManagerRequestsList pending />,
          isHidden: !moduleEnableLeaves && !moduleEnableOvertime
        },
      directManager &&
        Can(CASL.CASL_READ, CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TEAM_REQUESTS.id) && {
          path: CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TEAM_REQUESTS.path + '/request-details',
          element: <LeaveRequestDetails />,
          isHidden: !moduleEnableLeaves && !moduleEnableOvertime
        },
      directManager &&
        Can(CASL.CASL_READ, CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TIMEBOOKING.id) && {
          path: CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TIMEBOOKING.path + '/pending',
          element: <MyTeamPendingTimesheetRequests />
        },
      directManager &&
        Can(CASL.CASL_READ, CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TIMEBOOKING.id) && {
          path: CASL_OBJECTS.TEAM_ADMINISTRATION_SPACE_GROUP.childrenObjects.TIMEBOOKING.path + '/approve-timesheet',
          element: <ManagerTimesheet />
        }
    ].filter((route) => !route.isHidden)
  };
}
