import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import * as yup from 'yup';

export const EditableLineManagerComment = ({ lineManagerComments, children }) => {
  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      initialValues={{ lineManagerComments: lineManagerComments || '' }}
      validationSchema={yup.object().shape({
        lineManagerComments: yup.string().required('Please provide a comment')
      })}
    >
      {() => (
        <Form layout="vertical">
          <Form.Item
            style={{ margin: '20px auto', width: '40%' }}
            name="lineManagerComments"
            label="Line manager comment"
          >
            <Input.TextArea maxLength={500} showCount name="lineManagerComments" />
          </Form.Item>
          {children}
        </Form>
      )}
    </Formik>
  );
};
