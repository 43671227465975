import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const jobTitlesServices = {
  getJobTitlesAll() {
    return axios.get(API_PATH + '/jobTitle');
  },
  postJobTitle(payload) {
    return axios.post(API_PATH + '/jobTitle', payload);
  },
  patchJobTitle(payload) {
    return axios.patch(API_PATH + '/jobTitle/', payload);
  },
  deleteJobTitle(payload) {
    return axios.delete(API_PATH + '/jobTitle', { headers: {}, data: { id: payload } });
  }
};
