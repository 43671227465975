import { invoicePaymentsActions } from 'store/invoice-payments';
import { fetchSaga } from 'utils/fetch-saga';
import { all, put, takeLatest } from 'redux-saga/effects';
import { invoiceManagementServices } from './invoice-management.services';
import { invoiceManagementActions } from './invoice-management.slice';

export function* invoiceManagementRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getAllInvoicesByProjectId',
      actions: invoiceManagementActions,
      api: {
        method: invoiceManagementServices.getAllInvoicesByProjectId,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getAllPending',
      actions: invoiceManagementActions,
      api: {
        method: invoiceManagementServices.getAllPending,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'submitNewInvoice',
      actions: invoiceManagementActions,
      api: {
        method: invoiceManagementServices.submitNewInvoice,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchInvoice',
      actions: invoiceManagementActions,
      api: {
        method: invoiceManagementServices.patchInvoice,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'changeInvoicedDueDates',
      actions: invoiceManagementActions,
      api: {
        method: invoiceManagementServices.changeInvoicedDueDates,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteInvoice',
      actions: invoiceManagementActions,
      api: {
        method: invoiceManagementServices.deleteInvoice,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'projectDirectorDecision',
      actions: invoiceManagementActions,
      api: {
        method: invoiceManagementServices.projectDirectorDecision,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchInvoiceStatusChange',
      actions: invoiceManagementActions,
      api: {
        method: invoiceManagementServices.patchInvoiceStatusChange,
        callback: (res) => res
      }
    }),
    takeLatest(invoicePaymentsActions.postInvoicePaymentFulfilled.type, dispatchInvoiceStatusChange),
    takeLatest(invoicePaymentsActions.deleteInvoicePaymentFulfilled.type, dispatchInvoiceStatusChange),
    takeLatest(invoicePaymentsActions.patchInvoicePaymentFulfilled.type, dispatchInvoiceStatusChange)
  ]);
}

// for invoice status change to: Partially paid / fully paid
function* dispatchInvoiceStatusChange(action) {
  yield put(invoiceManagementActions.patchInvoiceStatusChange(action.payload));
}
