import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const qualityRiskLevelServices = {
  getQualityRiskLevels() {
    return axios.get(API_PATH + '/qualityRiskLevel');
  },
  postQualityRiskLevels(payload) {
    return axios.post(API_PATH + '/qualityRiskLevel', payload);
  },
  patchQualityRiskLevels(payload) {
    return axios.patch(API_PATH + '/qualityRiskLevel/', payload);
  }
};
