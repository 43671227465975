import { put } from 'redux-saga/effects';
import { myProjectsTeamsTimesheetsActions } from 'store/my-projects-teams-timesheets';
import { timesheetActions } from 'store/time-sheet/time-sheet.slice';
import { timesheetSettingsActions } from 'store/timesheet-settings';

// for adding/patching a newly submitted timesheets to proper timesheets state
export function* addingNewTimesheetsFromNotificationsToProperStates(action) {
  const res = yield action.payload;
  switch (res.notificationType.name) {
    case 'TIMESHEET_SUBMIT_TO_LINE_MANAGER':
      const newTeamMemberTimesheet = JSON.parse(res.content);
      yield put(timesheetActions.addNewTeamMemberTimesheet(newTeamMemberTimesheet));
      break;
    case 'TIMESHEET_SUBMIT_TO_PROJECT_DIRECTOR':
      const enable = true;
      yield put(myProjectsTeamsTimesheetsActions.makeRefreshButtonEnable(enable));
      break;
    case 'TIMESHEET_SETTINGS_CHANGED':
      const newTimesheetSettings = JSON.parse(res.content);
      yield put(timesheetSettingsActions.updateMyTimesheetSettingsFromNotificationResponse(newTimesheetSettings));
      break;
    case 'TIMESHEET_REJECTED_BY_LINE_MANAGER':
    case 'TIMESHEET_APPROVED_BY_LINE_MANAGER':
      const myUpdatedTimesheet = JSON.parse(res.content);
      yield put(timesheetActions.updateMyTimesheet(myUpdatedTimesheet));
      break;
    case 'TO_ALL':
      break;
  }
}
