import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  all: []
};

export const { actions: qualityRiskLevelActions, reducer: qualityRiskLevelReducer } = createSlice({
  name: 'qualityRiskLevel',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getQualityRiskLevels: (state) => {
      state.loading = true;
    },
    getQualityRiskLevelsFulfilled(state, action) {
      state.all = action.payload;
    },
    setQualityRiskLevels: (state, action) => {
      state.all = action.payload;
    },
    postQualityRiskLevels(state) {
      state.loading = true;
    },
    postQualityRiskLevelsFulfilled(state, action) {
      state.all = [...state.all, action.payload].sort((a, b) => a.description.localeCompare(b.description));
    },
    patchQualityRiskLevels(state) {
      state.loading = true;
    },
    patchQualityRiskLevelsFulfilled(state, action) {
      const arr = [...state.all];
      const foundIndex = arr.findIndex((item) => item.id === action.payload.id);
      arr.splice(foundIndex, 1, action.payload);
      state.all = arr;
    }
  }
});
