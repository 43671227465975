import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currencyActions } from 'store/currency';

const useCurrenciesHook = () => {
  const currencies = useSelector((state) => state.currencies.all);
  const loading = useSelector((state) => state.currencies.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currencies.length === 0 && !loading) {
      dispatch(currencyActions.getCurrenciesAll());
    }
  }, []);

  return {
    currencies,
    loading
  };
};

export default useCurrenciesHook;
