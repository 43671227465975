import { useTimesheet } from '../../hooks';
import { EditableLineManagerComment } from './editable-line-manager-comment';
import { NoneditableLineManagerComment } from './noneditable-line-manager-comment';

export const LineManagerComment = ({ editable, isMasterApproval, children }) => {
  const { timesheet } = useTimesheet();

  const lineManagerComment = timesheet?.lineManagerComments;
  const reviewedByProjectDirector = timesheet?.reviewedByProjectDirector;

  if ((editable && reviewedByProjectDirector) || (editable && isMasterApproval))
    return <EditableLineManagerComment lineManagerComments={lineManagerComment} children={children} />;
  return <NoneditableLineManagerComment comment={lineManagerComment} />;
};
