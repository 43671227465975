import { all, put, takeLatest } from 'redux-saga/effects';
import { employeeActions } from 'store/employee/employee.slice';
import { fetchSaga } from 'utils/fetch-saga';
import { salariesServices } from './salaries.services';
import { salariesActions } from './salaries.slice';

export function* salariesRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getSalariesAll',
      actions: salariesActions,
      api: {
        method: salariesServices.getSalariesAll,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchSalary',
      actions: salariesActions,
      api: {
        method: salariesServices.patchSalary,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postSalary',
      actions: salariesActions,
      api: {
        method: salariesServices.postSalary,
        callback: (res) => res
      }
    }),
    takeLatest(employeeActions.patchActivateFulfilled.type, dispatchPatchActivateSalary)
  ]);
}

function* dispatchPatchActivateSalary(action) {
  const res = yield action.payload;
  // const emp = yield employeeServices.getEmployeeByUserName(res.data.object.username);
  yield put(salariesActions.postSalary(res.id));
}
