import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { forecastServices } from './forecast.services';
import { forecastActions } from './forecast.slice';

export function* forecastRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getForecasts',
      actions: forecastActions,
      api: {
        method: forecastServices.getForecasts,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postForecast',
      actions: forecastActions,
      api: {
        method: forecastServices.postForecast,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchForecast',
      actions: forecastActions,
      api: {
        method: forecastServices.patchForecast,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteByActivityTypeId',
      actions: forecastActions,
      api: {
        method: forecastServices.deleteByActivityTypeId,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteByForecastId',
      actions: forecastActions,
      api: {
        method: forecastServices.deleteByForecastId,
        callback: (res) => res.data.data
      }
    })
  ]);
}
