import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { timesheetSettingsServices } from './timesheet-settings.services';
import { timesheetSettingsActions } from './timesheet-settings.slice';

export function* timesheetSettingsRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getAllTimesheetSettings',
      actions: timesheetSettingsActions,
      api: {
        method: timesheetSettingsServices.getTimesheetSettings,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getMyTimesheetSettings',
      actions: timesheetSettingsActions,
      api: {
        method: timesheetSettingsServices.getMyTimesheetSettings,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getImpersonateTimesheetSettings',
      actions: timesheetSettingsActions,
      api: {
        method: timesheetSettingsServices.getImpersonateTimesheetSettings,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getMasterTimesheetSettings',
      actions: timesheetSettingsActions,
      api: {
        method: timesheetSettingsServices.getMasterTimesheetSettings,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postNewSettings',
      actions: timesheetSettingsActions,
      api: {
        method: timesheetSettingsServices.postNewSettings,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchTimesheetSettingDays',
      actions: timesheetSettingsActions,
      api: {
        method: timesheetSettingsServices.patchTimesheetSettingDays,
        callback: (res) => {
          return res.data.data;
        }
      }
    }),
    fetchSaga({
      pattern: 'patchTimesheetSetting',
      actions: timesheetSettingsActions,
      api: {
        method: timesheetSettingsServices.putSettings,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteSetting',
      actions: timesheetSettingsActions,
      api: {
        method: timesheetSettingsServices.deleteSetting,
        callback: (res) => {
          return res.data.data;
        }
      }
    })
  ]);
}
