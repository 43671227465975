// WARNING: dont forget to check menu items, id must match last part of path
import * as CASL from 'casl/constants';
import { permissions } from 'views/admin-settings/permissions/permission-settings-columns';

export const OBJECTS = ({
  timesheetAutoReview,
  moduleEnableLeaves,
  moduleEnableOvertime,
  moduleEnableProjectTeams,
  moduleEnableProjectInvoices
}) => {
  return {
    // ******* ADMINISTRATION SETTINGS ******* //
    ADMINISTRATION_SETTINGS_GROUP: {
      title: 'Administration Settings',
      key: 'admin-settings',
      path: '/admin-settings',
      childrenObjects: {
        ROLE_SETTINGS: {
          id: 'rolesSettings',
          name: 'Roles Settings',
          path: '/admin-settings',
          permissions: permissions
        },
        PERMISSION_SETTINGS: {
          id: 'permissionSettings',
          name: 'Permission Settings',
          path: '/admin-settings',
          permissions: [
            { title: 'R', privilege: CASL.CASL_READ },
            { title: 'U', privilege: CASL.CASL_UPDATE }
          ]
        },
        IGNORE_PROJECT_TEAM: {
          id: 'filterByProjectTeam',
          name: 'Ignore Project Team Filters on Data',
          path: '',
          permissions: [{ title: 'R', privilege: CASL.CASL_READ }],
          hidden: !moduleEnableProjectTeams
        }
      }
    },

    // ******* APPLICATION SETTINGS ******* //

    APPLICATION_SETTINGS_GROUP: {
      title: 'Application Settings',
      key: 'app-settings',
      path: '/app-settings',
      childrenObjects: {
        TIMESHEET_SETTINGS: {
          id: 'timesheetSettings',
          name: 'Timesheet Settings',
          permissions: permissions
        },
        DISCIPLINE: {
          id: 'discipline',
          name: 'Discipline',
          permissions: permissions
        },
        JOB_TITLES: {
          id: 'jobTitles',
          name: 'Job Titles',
          permissions: permissions
        },
        CURRENCY: {
          id: 'currency',
          name: 'Currency',
          permissions: [
            { title: 'C', privilege: CASL.CASL_CREATE },
            { title: 'R', privilege: CASL.CASL_READ }
          ]
        },
        ACTIVITY_TYPE: {
          id: 'activityType',
          name: 'Activity Type',
          permissions: permissions
        },
        CONTRACT_TYPE: {
          id: 'contractType',
          name: 'Contract Type',
          permissions: permissions
        },
        PROJECT_STATUS: {
          id: 'projectStatus',
          name: 'Project Status',
          permissions: [{ title: 'R', privilege: CASL.CASL_READ }]
        },
        QUALITY_RISK_LEVEL: {
          id: 'qualityRiskLevel',
          name: 'Quality Risk Level',
          permissions: [
            { title: 'C', privilege: CASL.CASL_CREATE },
            { title: 'R', privilege: CASL.CASL_READ },
            { title: 'U', privilege: CASL.CASL_UPDATE }
          ]
        },
        ISMS_CLASSIFICATION: {
          id: 'ismsClassification',
          name: 'ISMS Classification',
          permissions: [{ title: 'R', privilege: CASL.CASL_READ }]
        },
        POSITION_CATEGORIES: {
          id: 'position-categories',
          name: 'Position Categories',
          permissions: permissions
        },
        VAT: {
          id: 'vat',
          name: 'VAT',
          permissions: permissions
        },
        POWER_BI: {
          id: 'powerbi',
          name: 'Power BI',
          permissions: permissions
        },
        BUSINESS_UNITS: {
          id: 'businessUnits',
          name: 'Business Units',
          permissions: permissions
        },
        PROJECT_VERTICAL_OPTIONS: {
          id: 'projectVerticalOptions',
          name: 'Project Vertical Options',
          permissions: permissions
        },
        FORECASTS: {
          id: 'forecasts',
          name: 'Forecasts',
          permissions: permissions
        }
      }
    },

    // ******* PROJECT SETTINGS ******* //

    PROJECT_SETTINGS_GROUP: {
      title: 'Project Settings',
      key: 'project-settings',
      childrenObjects: {
        PROJECTS: {
          id: 'projects',
          name: 'Projects',
          path: '/projects',
          permissions: [
            { title: 'C', privilege: CASL.CASL_CREATE },
            { title: 'R', privilege: CASL.CASL_READ },
            { title: 'D', privilege: CASL.CASL_DELETE }
          ]
        },
        PROJECT_DETAILS: {
          id: 'projectDetails',
          name: 'Project Details',
          path: '/projects',
          permissions: [
            { title: 'S', privilege: CASL.CASL_UPDATE_STATUS },
            { title: 'R', privilege: CASL.CASL_READ },
            { title: 'U', privilege: CASL.CASL_UPDATE }
          ]
        },
        DELEGATE_PROJECT_MANAGER: {
          id: 'delegate-project-manager',
          name: 'Delegate PM',
          path: '/projects/delegate-project-manager',
          permissions: [{ title: 'U', privilege: CASL.CASL_UPDATE }]
        }
      }
    },

    // ******* PROJECT TEAM ******* //
    WORKPACKAGES_GROUP: {
      title: 'Workpackages',
      key: 'workpackages',
      childrenObjects: {
        WORKPACKAGES: {
          id: 'workpackages',
          name: 'Workpackages',
          permissions: [...permissions, { title: 'S', privilege: CASL.CASL_UPDATE_STATUS }]
        },
        WORKPACKAGE_DETAILS: {
          id: 'workpackage-details',
          name: 'Workpackage Details',
          path: '/projects/workpackage-details',
          permissions: [{ title: 'R', privilege: CASL.CASL_READ }]
        },
        ACTIVITIES: {
          id: 'activities',
          name: 'Activities',
          path: '',
          permissions: permissions
        },
        WORKPACKAGE_SELLING_PRICE: {
          id: 'workpackageSellingPrice',
          name: 'Workpackage Selling Price',
          permissions: permissions
        },
        WORKPACKAGE_PROGRESS: {
          id: 'workpackageProgress',
          name: 'Workpackage Progress',
          permissions: permissions
        },
        WORKPACKAGE_BUDGET: {
          id: 'workpackageBudget',
          name: 'Workpackage Budget',
          path: '/projects/workpackage-details/create-budget',
          permissions: permissions
        }
      }
    },

    // ******* PROJECT TEAM ******* //
    PROJECT_TEAM_GROUP: {
      title: 'Project Team',
      key: 'project-team',
      childrenObjects: {
        PROJECT_TEAM: {
          id: 'project-team',
          name: 'Project Team',
          path: '',
          permissions: permissions,
          hidden: !moduleEnableProjectTeams
        },
        PROJECT_MANAGER_TIMESHEETS: {
          id: 'project-timesheet-requests',
          name: 'Project Timesheet Requests',
          path: '/projects/project-timesheet-requests',
          permissions: [
            { title: 'R', privilege: CASL.CASL_READ },
            { title: 'U', privilege: CASL.CASL_UPDATE }
          ],
          hidden: timesheetAutoReview
        }
      }
    },

    // ******* INVOICE MANAGEMENT ******* //
    INVOICE_MANAGEMENT_PERMISSIONS: {
      title: 'Invoice Mngmnt',
      key: 'invoice-management',
      hidden: !moduleEnableProjectInvoices,
      childrenObjects: {
        INVOICE_MANAGEMENT: {
          id: 'invoice-management',
          name: 'Invoice Management',
          path: '/projects',
          permissions: [...permissions, { title: 'A', privilege: CASL.CASL_APPROVE }],
          hidden: !moduleEnableProjectInvoices
        },
        INVOICE_PAYMENTS: {
          id: 'invoice-payments',
          name: 'Invoice Payments',
          path: '/projects/invoice-management/payments',
          permissions: permissions,
          hidden: !moduleEnableProjectInvoices
        }
      }
    },

    // ******* VARIOUS SETTINGS ******* //

    VARIOUS_GROUP_SETTINGS: {
      title: 'Various Settings',
      key: 'various-settings',
      childrenObjects: {
        ENTITY: {
          id: 'entities',
          name: 'Entity',
          path: '/entities',
          permissions: [
            { title: 'C', privilege: CASL.CASL_CREATE },
            { title: 'R', privilege: CASL.CASL_READ },
            { title: 'U', privilege: CASL.CASL_UPDATE }
          ]
        },
        PM_LEAVE_REQUESTS_ALL: {
          id: 'project-requests',
          name: 'PM Leave Requests',
          path: '/projects/project-requests',
          hidden: true
        },
        PM_LEAVE_REQUESTS_DETAILS: {
          id: 'request-details',
          name: 'PM Leave Requests Details',
          path: '/projects/project-requests/request-details',
          hidden: true
        },
        GENERAL_TIMEBOOKING_IMPERSONATE: {
          id: 'impersonate-employee',
          name: 'Impersonate Employee',
          path: '/employees-management/general-timebooking/impersonate-employee',
          permissions: [{ title: 'R', privilege: CASL.CASL_READ }]
        },
        GENERAL_TIMEBOOKING_MASTER_APPROVAL: {
          id: 'master-approval',
          name: 'Master Approval',
          path: '/employees-management/general-timebooking/master-approval',
          permissions: [
            { title: 'R', privilege: CASL.CASL_READ },
            { title: 'D', privilege: CASL.CASL_DELETE }
          ]
        },
        CURRENCY_EXCHANGE: {
          name: 'Currency Exchange',
          id: 'currency-exchange',
          path: '/currency-exchange',
          permissions: [
            { title: 'C', privilege: CASL.CASL_CREATE },
            { title: 'R', privilege: CASL.CASL_READ },
            { title: 'U', privilege: CASL.CASL_UPDATE }
          ]
        }
      }
    },

    // ******* EMPLOYEE SETTINGS ******* //

    EMPLOYEE_SETTINGS_GROUP: {
      title: 'Employee Settings',
      key: 'employee-settings',
      childrenObjects: {
        SYNC_EMPLOYEES: {
          id: 'syncEmployees',
          name: 'Synchronize Employees',
          path: '/employees-management/employees/pending/',
          permissions: [
            { title: 'C', privilege: CASL.CASL_CREATE },
            { title: 'R', privilege: CASL.CASL_READ },
            { title: 'D', privilege: CASL.CASL_DELETE }
          ]
        },
        HR_LEAVE_REQUESTS_ALL: {
          id: 'all-approved-leave-requests',
          name: 'HR Leave Requests',
          path: '/employees-management/all-approved-leave-requests',
          permissions: [
            { title: 'R', privilege: CASL.CASL_READ },
            { title: 'D', privilege: CASL.CASL_DELETE }
          ],
          hidden: !moduleEnableLeaves
        },
        HR_LEAVE_REQUEST_DETAILS: {
          id: 'request-details',
          name: 'HR Leave Requests Details',
          path: '/employees-management/all-approved-leave-requests/request-details',
          permissions: [{ title: 'R', privilege: CASL.CASL_READ }],
          hidden: true
        },
        HR_OVERTIME_REQUESTS: {
          id: 'overtime-requests',
          name: 'HR Overtime Requests',
          path: '/employees-management/employees/overtime-requests',
          permissions: [
            { title: 'R', privilege: CASL.CASL_READ },
            { title: 'D', privilege: CASL.CASL_DELETE }
          ],
          hidden: !moduleEnableOvertime
        },
        HR_OVERTIME_REQUEST_DETAILS: {
          id: 'overtime-details',
          name: 'HR Overtime Request Details',
          path: '/employees-management/employees/overtime-details',
          permissions: [{ title: 'R', privilege: CASL.CASL_READ }],
          hidden: true
        },
        EMPLOYEES: {
          id: 'employees',
          name: 'Employees Info',
          path: '/employees-management/employees',
          permissions: [
            { title: 'R', privilege: CASL.CASL_READ },
            { title: 'U', privilege: CASL.CASL_UPDATE }
          ]
        },
        EXTENDED_EMPLOYEES: {
          id: 'employeesPersonalInformation',
          name: 'Extended Employees',
          path: null,
          permissions: [{ title: 'R', privilege: CASL.CASL_READ }]
        },
        EMPLOYEES_SALARIES: {
          id: 'salaries',
          name: 'Employees Salaries',
          path: '/employees-management/employees/salaries',
          permissions: [
            { title: 'R', privilege: CASL.CASL_READ },
            { title: 'U', privilege: CASL.CASL_UPDATE }
          ]
        },
        HR_DELEGATE_MANAGER: {
          id: 'delegate-manager',
          name: 'Delegate Manager',
          path: '/employees-management/employees/delegate-manager',
          permissions: [{ title: 'R', privilege: CASL.CASL_READ }]
        }
      }
    },

    // ******* MY PERSONAL SPACE SETTINGS ******* //
    MY_PERSONAL_SPACE_GROUP: {
      title: 'Personal Space Settings',
      key: 'personal-space-settings',
      childrenObjects: {
        MY_TIME_BOOKING: {
          id: 'my-timebooking',
          name: 'My Time Booking',
          path: '/my-personal-space/my-timebooking',
          permissions: [{ title: 'R', privilege: CASL.CASL_READ }]
        },
        MY_LEAVE_REQUESTS: {
          id: 'my-leave-requests',
          name: 'My Leave Requests',
          path: '/my-personal-space/my-leave-requests',
          permissions: [{ title: 'R', privilege: CASL.CASL_READ }],
          hidden: !moduleEnableLeaves
        },
        MY_OVERTIME_REQUESTS: {
          id: 'my-overtime-requests',
          name: 'My Overtime Requests',
          path: '/my-personal-space/my-overtime-requests',
          permissions: [{ title: 'R', privilege: CASL.CASL_READ }],
          hidden: !moduleEnableOvertime
        }
      }
    },

    // ******* TEAM ADMINISTRATION SPACE SETTINGS ******* //
    TEAM_ADMINISTRATION_SPACE_GROUP: {
      title: 'Team Administration Space Settings',
      key: 'team-administration-space-settings',
      childrenObjects: {
        TIMEBOOKING: {
          id: 'team-timesheet-requests',
          name: 'Timebooking',
          path: '/my-team-administration/team-timesheet-requests',
          permissions: [{ title: 'R', privilege: CASL.CASL_READ }]
        },
        TEAM_REQUESTS: {
          id: 'team-leave-requests',
          name: 'Team Requests',
          path: '/my-team-administration/team-leave-requests',
          permissions: [{ title: 'R', privilege: CASL.CASL_READ }]
        }
      }
    }
  };
};

export default OBJECTS;
