// project imports
import MainLayout from 'layout/main-layout';
import { lazy } from 'react';
import Loadable from 'ui-component/loadable';
import Can from 'casl/can';
import * as CASL from 'casl/constants';
import OBJECTS from 'casl/objects';

// personal space routing
const EntitiesList = Loadable(lazy(() => import('views/entities')));

// ==============================|| MAIN ROUTING ||============================== //

export default function EntitiesRoutes() {
  const CASL_OBJECTS = OBJECTS({});
  return {
    path: CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.ENTITY.path,
    element: <MainLayout />,
    children: [
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.ENTITY.id)
        ? [
            {
              index: true,
              element: <EntitiesList />
            }
          ]
        : [])
    ]
  };
}
