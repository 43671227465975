import { HistoryOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { currencyExchangeActions } from 'store/currency-exchange/currency-exchange.slice';

export const HistoryOpen = ({ item }) => {
  const dispatch = useDispatch();

  return (
    <Button type="link" onClick={() => dispatch(currencyExchangeActions.showHistory(item))}>
      <HistoryOutlined />
    </Button>
  );
};
