import { Progress, Tooltip, Typography } from 'antd';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import { useRef } from 'react';
import { useTimesheet } from '../../hooks';

const { Text } = Typography;

const ErrorTitle = (props) => {
  return (
    <>
      {!props.minHoursMatch ? <span style={{ display: 'block' }}>Min. hours don't match</span> : ''}
      {!props.maxHoursMatch ? <span style={{ display: 'block' }}>Max. hours don't match</span> : ''}
    </>
  );
};

export const DayHeader = ({ days, weekDay, index }) => {
  const textWrapper = useRef();

  const formikValues = useFormikContext();
  const errors = formikValues?.errors || {};

  const currentDayError = errors?.days?.[index];
  const { calculateWeekHours } = useTimesheet();

  const { hoursPerDay } = calculateWeekHours();

  return (
    <>
      <div ref={textWrapper} style={{ position: 'relative' }}>
        <Tooltip
          title={<ErrorTitle {...currentDayError} />}
          // getTooltipContainer={(container) => container}
          color="rgb(255, 77, 79)"
          open={(currentDayError && (!currentDayError.minHoursMatch || !currentDayError.maxHoursMatch)) || false}
          placement="top"
          overlayStyle={{ width: 80, fontSize: 10, textAlign: 'center' }}
          overlayInnerStyle={{ textAlign: 'center' }}
          destroyTooltipOnHide
        >
          <Text style={{ display: 'block' }}>{weekDay.weekDay.description}</Text>
        </Tooltip>
      </div>
      <Text type="secondary">
        {days?.[index]?.theDate ? format(new Date(days[index]?.theDate), 'dd-MM-yyyy') : '__-__-____'}
      </Text>
      <Progress
        format={() => `${hoursPerDay[index]?.manHours}H`}
        percent={(parseFloat(hoursPerDay[index]?.manHours) * 100) / weekDay.dayTargetHours}
        status={hoursPerDay[index]?.manHours > weekDay.dayTargetHours ? 'exception' : ''}
      />
    </>
  );
};
