import { createSlice } from '@reduxjs/toolkit';
import { compareAsc } from 'date-fns/esm';

const initialState = {
  loading: false,
  error: false,
  exchanges: null,
  isFormModalOpen: false,
  editingExchange: null,
  historyItem: {},
  historyModalOpen: false
};

export const { actions: currencyExchangeActions, reducer: currencyExchangeReducer } = createSlice({
  name: 'exchange',
  initialState: initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    getExchanges() {},
    getExchangesFulfilled(state, action) {
      const currenciesObject = action.payload
        .filter((currency) => currency.fromCurrency !== currency.toCurrency)
        .reduce((accumulator, item) => {
          const prevFromArray = accumulator[item.fromCurrency] || [];
          const prevItemIndex = accumulator[item.fromCurrency]?.findIndex(
            (prevItem) => prevItem.toCurrency === item.toCurrency
          );
          const prevItem = accumulator[item.fromCurrency]?.[prevItemIndex];

          let newFromArray;
          const newObj = {
            ...(item.isActive ? item : prevItem?.isActive ? prevItem : item),
            history: [item, ...(prevItem?.history || [])].sort((a, b) => {
              return compareAsc(new Date(a.date), new Date(b.date));
            })
          };

          if (prevItemIndex < 0) {
            newFromArray = [...prevFromArray, newObj];
          } else {
            newFromArray = [
              ...prevFromArray.slice(0, prevItemIndex),
              newObj,
              ...prevFromArray.slice(prevItemIndex + 1)
            ];
          }

          return {
            ...accumulator,
            [item.fromCurrency]: newFromArray
          };
        }, {});

      state.exchanges = currenciesObject;
    },
    openAddModal(state) {
      state.isFormModalOpen = true;
      state.editingExchange = null;
    },
    openEditModal(state, action) {
      state.isFormModalOpen = true;
      state.editingExchange = action.payload;
    },
    closeFormModal(state) {
      state.isFormModalOpen = false;
    },
    addExchange() {},
    addExchangeFulfilled(state, { payload: { conversion: newConversion } }) {
      const fromExchanges = state.exchanges[newConversion.fromCurrency] || [];

      const toExchangesIndex = fromExchanges.findIndex((exchange) => exchange.toCurrency === newConversion.toCurrency);
      const toExchanges = fromExchanges[toExchangesIndex] || { history: [] };

      fromExchanges.splice(toExchangesIndex < 0 ? fromExchanges.length : toExchangesIndex, 1, {
        ...(newConversion.isActive ? newConversion : toExchanges),
        history: [
          ...toExchanges.history.map((exchange) => ({
            ...exchange,
            isActive: newConversion.isActive ? false : exchange.isActive
          })),
          newConversion
        ]
      });

      fromExchanges[toExchangesIndex < 0 ? fromExchanges.length - 1 : toExchangesIndex].history.sort((a, b) => {
        return compareAsc(new Date(a.date), new Date(b.date));
      });

      state.exchanges = {
        ...state.exchanges,
        [newConversion.fromCurrency]: fromExchanges
      };
      state.isFormModalOpen = false;
    },
    showHistory(state, { payload }) {
      state.historyItem = payload;
      state.historyModalOpen = true;
    },
    closeHistory(state, { payload }) {
      state.historyModalOpen = payload;
    },
    editExchange() {},
    editExchangeFulfilled(state, { payload }) {
      const payloadConversion = payload.conversion;
      let toggledConversionDTOs = payload.toggledConversionResponses[0];
      let changedFromConversion = state.exchanges[payloadConversion.fromCurrency];

      const changedConversionIndex = changedFromConversion.findIndex(
        (conversion) => conversion.toCurrency === payloadConversion.toCurrency
      );

      let changedConversion = changedFromConversion[changedConversionIndex];

      const newHistory = changedConversion.history
        .map((exchange) => ({
          ...(exchange.id !== payloadConversion.id ? exchange : payloadConversion),
          isActive:
            payloadConversion.id === exchange.id
              ? payloadConversion.isActive
              : toggledConversionDTOs?.isActive ?? exchange.isActive
        }))
        .sort((a, b) => {
          return compareAsc(new Date(a.date), new Date(b.date));
        });

      changedConversion = {
        ...newHistory.find((conversion) => conversion.isActive),
        history: newHistory
      };

      changedFromConversion = [
        ...changedFromConversion.slice(0, changedConversionIndex),
        changedConversion,
        ...changedFromConversion.slice(changedConversionIndex + 1)
      ];

      state.exchanges = {
        ...state.exchanges,
        [payloadConversion.fromCurrency]: changedFromConversion
      };
    }
  }
});
