import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const invoicePaymentsServices = {
  getInvoicePaymentsByInvoiceId(invoiceId) {
    return axios.get(API_PATH + `/payment/all?invoiceId=${invoiceId}`);
  },
  postInvoicePayment(payment) {
    return axios.post(API_PATH + '/payment', payment);
  },
  patchInvoicePayment(payment) {
    return axios.patch(API_PATH + '/payment', payment);
  },
  deleteInvoicePayment(payment) {
    return axios.delete(API_PATH + '/payment', {
      data: payment
    });
  }
};
