import { all, takeLatest } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { addingNewTimesheetsFromNotificationsToProperStates } from './notifications-watcher';
import { notificationsServices } from './notifications.services';
import { notificationsActions } from './notifications.slice';

export function* notificationsRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getAllUnreadNotifications',
      actions: notificationsActions,
      api: {
        method: notificationsServices.getAllUnreadNotifications,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getAllReadNotifications',
      actions: notificationsActions,
      api: {
        method: notificationsServices.getAllReadNotifications,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'reviewNotification',
      actions: notificationsActions,
      api: {
        method: notificationsServices.reviewNotification,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'receiveNewNotification',
      actions: notificationsActions,
      api: {
        method: notificationsServices.receiveNewNotification,
        callback: (res) => res
      }
    }),
    takeLatest(
      notificationsActions.receiveNewNotificationFulfilled.type,
      addingNewTimesheetsFromNotificationsToProperStates
    )
  ]);
}
