import { IconChecklist } from '@tabler/icons';
import { List, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { notificationsActions } from 'store/notifications';
import useNotificationHook from '../hooks/notificationHook';

export const DefaultLineManagerNotificationsListItem = ({ unreadNotificationsAll, handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { teamPendingTimesheetsPath } = useNotificationHook();
  const lineManagerNotifications = unreadNotificationsAll.filter(
    (notification) => notification.notificationType?.name === 'TIMESHEET_SUBMIT_TO_LINE_MANAGER'
  );
  const lineManagerNotificationsIds = lineManagerNotifications.map((LMNotification) => LMNotification.id);
  const isNotificationReviewed = lineManagerNotifications.map((notification) => notification.isReviewed);

  return (
    <List.Item
      className={!isNotificationReviewed[0] && 'custom-notification-list-item'}
      style={{
        marginRight: '5px',
        borderLeft: !isNotificationReviewed[0] ? '5px solid #789637' : '5px solid #999999'
      }}
      onClick={() => {
        if (!isNotificationReviewed[0]) {
          navigate(teamPendingTimesheetsPath);
          dispatch(notificationsActions.reviewNotification({ ids: lineManagerNotificationsIds }));
          handleClose(true);
        }
      }}
    >
      <List.Item.Meta
        style={{ margin: 0 }}
        avatar={<IconChecklist size={22} color={!isNotificationReviewed[0] ? '#789637' : '#999999'} />}
        title={<Typography.Text style={{ fontSize: 14 }}>New Timesheets</Typography.Text>}
        description={
          <>
            {
              <Typography.Text strong style={{ fontSize: 14 }}>
                {lineManagerNotifications.length}
              </Typography.Text>
            }{' '}
            {lineManagerNotifications.length > 1 ? 'new timesheets submitted' : 'new timesheet submitted'}
          </>
        }
      />
    </List.Item>
  );
};
