import { Col, Result, Row, Spin } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { commonActions } from 'store/common/common.slice';

function getFaviconEl() {
  return document.getElementById('favicon');
}

export const AppLoader = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.currentUser.user);
  const ready = useSelector((state) => state.common.ready);

  const ENTITY_CODE = useSelector((state) => state.appConfiguration.entityCode);
  const ENTITY_NAME = useSelector((state) => state.appConfiguration.entityName);

  const favicon = getFaviconEl();
  document.title = `${ENTITY_NAME} - ERPLite`;
  favicon.href = ENTITY_CODE ? favicon.href.replace('favicon', `${ENTITY_CODE}_favicon`) : 'favicon';

  useEffect(() => {
    if (!currentUser) {
      dispatch;
    }
    if (!ready && currentUser && ENTITY_NAME) {
      dispatch(commonActions.setCasls());
    }
  }, [ENTITY_NAME]);

  return (
    <Row style={{ height: '100vh' }} justify="center" align="middle">
      <Col span={24}>
        <Result
          icon={<></>}
          extra={<Spin size="large" tip="hang on, we are preparing the app for you..." />}
          title={'Welcome back ' + currentUser?.fullName + '!'}
        />
      </Col>
      <Col span={24}></Col>
      {/* <Spin size="large" tip="Fetching Employees..." spinning={employeesLoading} /> */}
    </Row>
  );
};
