// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './nav-group';
import menuItem from 'menu-items';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const navItems = menuItem()
    .items.filter((item) => !item.isHidden)
    .map((item) => {
      if (item) {
        switch (item.type) {
          case 'group':
            return <NavGroup key={item.id} item={item} />;
          default:
            return (
              <Typography key={item.id} variant="h6" color="error" align="center">
                Menu Items Error
              </Typography>
            );
        }
      }
    });

  return <>{navItems}</>;
};

export default MenuList;
