// project imports
import Can from 'casl/can';
import * as CASL from 'casl/constants';
import OBJECTS from 'casl/objects';
import MainLayout from 'layout/main-layout';
import { lazy } from 'react';
import { useSelector } from 'react-redux';
import Loadable from 'ui-component/loadable';
import { EmployeeTimesheet } from 'views/timesheet/timesheet/pages';

// personal space routing
const TimesheetLandingPage = Loadable(lazy(() => import('views/timesheet/')));
const TimesheetHistory = Loadable(lazy(() => import('views/timesheet/timesheet-history')));

const LeaveRequestDetails = Loadable(lazy(() => import('views/leave-requests/leave-request-details')));
const LeaveRequestLandingPage = Loadable(lazy(() => import('views/leave-requests/')));

const OvertimeRequestLandingPage = Loadable(
  lazy(() => import('views/overtime-request/employees-requests-landing-page'))
);
const OvertimeRequestDetails = Loadable(lazy(() => import('views/overtime-request/overtime-request-details')));

const SubmitUnpaidLeave = Loadable(lazy(() => import('views/leave-requests/employee-requests/submit-unpaid-leave')));
const MyUnpaidLeaveHistory = Loadable(
  lazy(() => import('views/leave-requests/employee-requests/my-unpaid-leave-history'))
);

const SubmitBusinessTrip = Loadable(lazy(() => import('views/leave-requests/employee-requests/submit-business-trip')));
const MyBusinessTripRequestsHistory = Loadable(
  lazy(() => import('views/leave-requests/employee-requests/my-business-trip-requests-history'))
);

const SubmitPaidLeave = Loadable(lazy(() => import('views/leave-requests/employee-requests/submit-paid-leave')));
const MyPaidLeaveHistory = Loadable(lazy(() => import('views/leave-requests/employee-requests/my-paid-leave-history')));

// const MyLeaveRequestsHistory = Loadable(lazy(() => import('views/leave-requests/EmployeeRequests/MyLeaveRequestsHistory')));

// ==============================|| MAIN ROUTING ||============================== //

export default function MainRoutes() {
  const { timesheetAutoReview, moduleEnableLeaves, moduleEnableOvertime } = useSelector(
    (state) => state.appConfiguration
  );
  const CASL_OBJECTS = OBJECTS({});
  return {
    path: '/my-personal-space',
    element: <MainLayout />,
    children: [
      // My Time Booking routes
      Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_TIME_BOOKING.id) && {
        path: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_TIME_BOOKING.path,
        element: <TimesheetLandingPage />
      },
      Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_TIME_BOOKING.id) && {
        path: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_TIME_BOOKING.path + '/submit-timesheet',
        element: <EmployeeTimesheet />
      },
      Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_TIME_BOOKING.id) && {
        path: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_TIME_BOOKING.path + '/timesheet-details',
        element: <EmployeeTimesheet />
      },
      Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_TIME_BOOKING.id) && {
        path: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_TIME_BOOKING.path + '/history/approved',
        element: <TimesheetHistory type={'Approved'} />
      },
      Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_TIME_BOOKING.id) && {
        path: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_TIME_BOOKING.path + '/history/pending',
        element: <TimesheetHistory type={'Pending'} />
      },
      Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_TIME_BOOKING.id) && {
        path: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_TIME_BOOKING.path + '/history/rejected',
        element: <TimesheetHistory type={'Rejected'} />
      },

      // My Leave Requests routes
      Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.id) && {
        path: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.path,
        element: <LeaveRequestLandingPage />,
        isHidden: !moduleEnableLeaves
      },
      Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.id) && {
        path: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.path + '/submit-business-trip',
        element: <SubmitBusinessTrip />,
        isHidden: !moduleEnableLeaves
      },
      Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.id) && {
        path: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.path + '/submit-unpaid-leave',
        element: <SubmitUnpaidLeave />,
        isHidden: !moduleEnableLeaves
      },
      Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.id) && {
        path: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.path + '/submit-paid-leave',
        element: <SubmitPaidLeave />,
        isHidden: !moduleEnableLeaves
      },
      Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.id) && {
        path: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.path + '/business-trip-history',
        element: <MyBusinessTripRequestsHistory />,
        isHidden: !moduleEnableLeaves
      },
      Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.id) && {
        path: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.path + '/unpaid-leave-history',
        element: <MyUnpaidLeaveHistory />,
        isHidden: !moduleEnableLeaves
      },
      Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.id) && {
        path: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.path + '/paid-leave-history',
        element: <MyPaidLeaveHistory />,
        isHidden: !moduleEnableLeaves
      },
      Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.id) && {
        path: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_LEAVE_REQUESTS.path + '/request-details',
        element: <LeaveRequestDetails />,
        isHidden: !moduleEnableLeaves
      },

      // My Overtime Requests routes
      Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_OVERTIME_REQUESTS.id) && {
        path: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_OVERTIME_REQUESTS.path,
        element: <OvertimeRequestLandingPage />,
        isHidden: !moduleEnableOvertime
      },
      Can(CASL.CASL_READ, CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_OVERTIME_REQUESTS.id) && {
        path: CASL_OBJECTS.MY_PERSONAL_SPACE_GROUP.childrenObjects.MY_OVERTIME_REQUESTS.path + '/request-details',
        element: <OvertimeRequestDetails />,
        isHidden: !moduleEnableOvertime
      }
    ].filter((route) => !route.isHidden)
  };
}
