import { lazy } from 'react';

// project imports
import MainLayout from 'layout/main-layout';
import Loadable from 'ui-component/loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      index: true,
      element: <DashboardDefault />
    }
  ]
};

export default MainRoutes;
