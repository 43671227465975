import { all, takeLatest } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { ORIGIN } from 'views/leave-requests/column-data';
import { leaveRequestServices } from './leave-request.services';
import { leaveRequestActions } from './leave-request.slice';

export function* leaveRequestRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getLeaveRequestTypes',
      actions: leaveRequestActions,
      api: {
        method: leaveRequestServices.getLeaveRequestTypes,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getMyBusinessTripRequests',
      actions: leaveRequestActions,
      api: {
        method: leaveRequestServices.getMyBusinessTripRequests,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getMyUnpaidLeaveRequests',
      actions: leaveRequestActions,
      api: {
        method: leaveRequestServices.getMyUnpaidLeaveRequests,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getMyPaidLeaveRequests',
      actions: leaveRequestActions,
      api: {
        method: leaveRequestServices.getMyPaidLeaveRequests,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getHRLeaveRequestsAll',
      actions: leaveRequestActions,
      api: {
        method: leaveRequestServices.getHRLeaveRequestsAll,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getHistoryOfRequestsByLineManager',
      actions: leaveRequestActions,
      api: {
        method: leaveRequestServices.getHistoryOfRequestsByLineManager,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getPendingLeaveRequestsByDirectManagerId',
      actions: leaveRequestActions,
      api: {
        method: leaveRequestServices.getPendingLeaveRequestsByDirectManagerId,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getPendingBusinessTripByWorkpackageManager',
      actions: leaveRequestActions,
      api: {
        method: leaveRequestServices.getPendingBusinessTripByWorkpackageManager,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getHistoryBusinessTripByWorkpackageManager',
      actions: leaveRequestActions,
      api: {
        method: leaveRequestServices.getHistoryBusinessTripByWorkpackageManager,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'submitLeaveRequest',
      actions: leaveRequestActions,
      api: {
        method: leaveRequestServices.submitLeaveRequest,
        callback: (res) => {
          return { data: res.data, nav: res.nav };
        }
      }
    }),
    fetchSaga({
      pattern: 'patchLeaveRequest',
      actions: leaveRequestActions,
      api: {
        method: leaveRequestServices.patchLeaveRequest,
        callback: (res) => {
          return { data: res.data, nav: res.nav };
        }
      }
    }),
    fetchSaga({
      pattern: 'workpackageManagerDecision',
      actions: leaveRequestActions,
      api: {
        method: leaveRequestServices.workpackageManagerDecision,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteHrRequestById',
      actions: leaveRequestActions,
      api: {
        method: leaveRequestServices.deleteHrRequestById,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteEmployeeBusinessTrip',
      actions: leaveRequestActions,
      api: {
        method: leaveRequestServices.deleteHrRequestById,
        callback: (res) => res.data.data
      }
    }),
    takeLatest(
      [leaveRequestActions.submitLeaveRequestFulfilled.type, leaveRequestActions.patchLeaveRequestFulfilled.type],
      dispatchRedirect
    )
  ]);
}

function* dispatchRedirect(action) {
  const nav = action.payload.nav;
  const res = yield action.payload.data;
  nav(ORIGIN.EMPLOYEE.path, {
    state: {
      request: res.data.data,
      employee: true
    },
    replace: true
  });
}
