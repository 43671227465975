import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const disciplinesServices = {
  getDisciplinesAll() {
    return axios.get(API_PATH + '/discipline');
  },
  postDiscipline(payload) {
    return axios.post(API_PATH + '/discipline', payload);
  },
  patchDiscipline(payload) {
    return axios.patch(API_PATH + '/discipline/', payload);
  },
  deleteDiscipline(payload) {
    return axios.delete(API_PATH + '/discipline', { headers: {}, data: { id: payload } });
  }
};
