import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/loadable';
import MinimalLayout from 'layout/minimal-layout';
import { Navigate } from 'react-router';

// login option 3 routing
const PublicApp = Loadable(lazy(() => import('app-public')));
const AppPublicLogout = Loadable(lazy(() => import('views/pages/authentication/authentication3/logout')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'login',
      element: <PublicApp />
    },
    {
      path: 'logout',
      element: <AppPublicLogout />
    }
  ]
};

export default AuthenticationRoutes;
