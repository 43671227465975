import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { appConfigurationServices } from './app-configuration.services';
import { appConfigurationActions } from './app-configuration.slice';

export function* appConfigurationRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getAppConfiguration',
      actions: appConfigurationActions,
      api: {
        method: appConfigurationServices.getAppConfiguration,
        callback: (res) => res
      }
    })
  ]);
}
