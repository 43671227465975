import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/loadable';

const Error404 = Loadable(lazy(() => import('views/errors/404')));

// ==============================|| MAIN ROUTING ||============================== //

const ErrorRoutes = {
  path: '*',
  element: <Error404 />
};

export default ErrorRoutes;
