import { Card, Col, Row, Space, Typography, Progress, Alert } from 'antd';
import { useSelector } from 'react-redux';
import { useTimesheet } from '../../hooks';

const { Text } = Typography;

export const HoursNotification = () => {
  const isInOvertime = useSelector((state) => state.timesheet.days)?.isInOvertime;

  const { calculateWeekHours, weekTargetHours, dayTargetHours } = useTimesheet();
  const { totalHours } = calculateWeekHours();

  return (
    <Row gutter={[16, 16]} align={'middle'}>
      {isInOvertime && (
        <Col>
          <Alert
            description={
              <>
                <Text type="secondary">Overtime is enabled</Text>
                <p style={{ margin: 0 }}>
                  Extra hours: <strong>{totalHours - weekTargetHours < 0 ? 0 : totalHours - weekTargetHours}</strong>
                </p>
              </>
            }
          ></Alert>
        </Col>
      )}
      <Col>
        <Card
          style={{
            border: '1px solid #e02c2c',
            padding: 0,
            borderRadius: 4
          }}
          bodyStyle={{ padding: 5 }}
          className={'targetHoursAlert'}
        >
          <Space direction="vertical" size={[0, 0]}>
            <Text strong>
              Weekly Target Hours: <Text type="danger">{weekTargetHours}</Text>
            </Text>
            <Text strong>
              Daily Target Hours: <Text type="danger">{dayTargetHours}</Text>
            </Text>
          </Space>
        </Card>
      </Col>
      <Col>
        <Progress
          type="circle"
          width={70}
          percent={(totalHours * 100) / weekTargetHours}
          status={totalHours <= weekTargetHours ? (totalHours < weekTargetHours ? 'active' : 'success') : 'exception'}
          format={() => `${totalHours} H`}
        />
      </Col>
    </Row>
  );
};
