import { Ability, AbilityBuilder } from '@casl/ability';
import * as CASL from './constants';

const ability = new Ability();

export default (action, subject) => {
  return ability.can(action, subject);
};

export const caslHook = (casls, responsibility) => {
  const { can, rules, cannot } = new AbilityBuilder();
  casls.map((casl) => can(casl.privilege, casl.object));
  if (responsibility.directManager) {
    can(CASL.CASL_READ, 'teamAdministration');
  }
  if (responsibility.workPackageManager) {
    can(CASL.CASL_READ, 'leaveRequests/project');
  }
  ability.update(rules);
};
