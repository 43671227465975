import { Avatar, ButtonBase } from '@mui/material';
import { IconChartLine } from '@tabler/icons';
import { Tooltip } from 'antd';
import Can from 'casl/can';
import * as CASL from 'casl/constants';
import { useHeaderHook } from '../useHeaderHook';

export const CurrencyExchangeButton = () => {
  const { navigate, CASL_OBJECTS, theme } = useHeaderHook();

  return Can(CASL.CASL_READ, CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.CURRENCY_EXCHANGE.id) ? (
    <Tooltip title="Currency Exchange">
      <ButtonBase sx={{ borderRadius: '12px' }}>
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            transition: 'all .2s ease-in-out',
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.dark,
            '&[aria-controls="menu-list-grow"],&:hover': {
              background: theme.palette.secondary.dark,
              color: theme.palette.secondary.light
            }
          }}
          onClick={() => {
            navigate(CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.CURRENCY_EXCHANGE.path);
          }}
          color="inherit"
        >
          <IconChartLine stroke={1.5} size="1.3rem" />
        </Avatar>
      </ButtonBase>
    </Tooltip>
  ) : (
    <></>
  );
};
