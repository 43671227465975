import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  responsibilities: null,
  loading: false,
  error: null,
  ticketValid: false,
  sessionValid: false,
  validatingTicket: false,
  validatingSession: false,
  mySubordinates: []
};

export const { actions: currentUserActions, reducer: currentUserReducer } = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, { payload }) {
      state.error = payload;
    },
    getUserInfo(state) {
      state.loading = true;
    },
    getUserInfoFulfilled(state, { payload }) {
      state.user = payload.user;
      state.responsibilities = payload.responsibility;
    },
    getMySubordinates(state) {
      state.loading = true;
    },
    getMySubordinatesFulfilled(state, { payload }) {
      state.mySubordinates = payload;
    },
    validateTicket(state) {
      state.loading = true;
      state.validatingTicket = true;
    },
    validateTicketFulfilled(state, { payload }) {
      state.ticketValid = payload.data;
      state.validatingTicket = false;
    },
    validateSession(state) {
      state.loading = true;
      state.validatingSession = true;
    },
    validateSessionFulfilled(state, { payload }) {
      state.sessionValid = payload.data;
      state.validatingSession = false;
    },
    resetState(state) {
      state.user = null;
      state.responsibilities = null;
      state.myLineManager = null;
      state.ticketValid = false;
      state.sessionValid = false;
    }
  }
});
